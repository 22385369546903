import React, {useEffect, useState} from "react";
import {camelToReadable, formatDisplayEnumValue, InformativeMSG, toCapsFirst} from "../../utils/Utils";
import Container from "react-bootstrap/Container";
import FormHandler from "../../shared/utils/FormHandler";
import {validateCCRUpdate} from "../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../shared/actions/setting";
import {useDispatch} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import FilterHandler from "../../shared/utils/CombinedFilterHandler";
import {dateFormat} from "../../shared/utils/utils";
import SupplyCallHistory from "./supply-call-history";
import asc from "../../images/icons/asc-sort.svg";
import dsc from "../../images/icons/dsc-sort.svg";
import sort from "../../images/icons/sort-option.svg";
import SupplyRequestCall from "./supply-request-call";
import DemandCallHistory from "./demand-call-history";
import SupplyCreateOrderDetail from "./supplyCreate-order-detail";

const SupplyRequests = () => {
  const [supplyList, setSupplyList] = useState([]);
  const [supplyListUnFiltered, setSupplyListUnFiltered] = useState([]);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLogVisible, setIsLogVisible] = useState(false);
  const [isCallVisible, setIsCallVisible] = useState(false);
  const [isRequestCall, setIsRequestCall] = useState(false);
  const [isMatchCall, setIsMatchCall] = useState(false);
  const [isRequestViewVisible, setIsRequestViewVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [selectedSupplierData, setSelectedSupplierData] = useState([]);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isInterestOrderVisible, setIsInterestOrderVisible] = useState(false);
  const [isMatchOrderVisible, setIsMatchOrderVisible] = useState(false);
  const [createdOrderData, setCreatedOrderData] = useState([]);
  const [orderCreated, setOrderCreated] = useState(false);
  const [buyer, setBuyer] = useState(null);
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [farmId, setFarmId] = useState(null);
  const [buyerId, setBuyerId] = useState(null);
  const [filteredCcrNames, setFilteredCcrNames] = useState([]);
  const [ccrList, setCcrList] = useState([]);
  const [ccrValue, setCCRValue] = useState("");
  const [sortOrder, setSortOrder] = useState('');
  const [supplyLogList, setSupplyLogList] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [matchData, setMatchData] = useState([]);
  const [sortField, setSortField] = useState('');
  const [col, setCol] = useState('');
  const [order, setOrder] = useState(0);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
  } = FormHandler(supplyRequest, validateCCRUpdate);

  const [dateRange, setDateRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });

  const onCheck = (e) => {
    const dates = {
      fromDate: dateFormat(new Date(e[0])),
      toDate: dateFormat(new Date(e[1]))
    };
    setDateRange(dates);
  };

  const filteredSupplyList = (supplyList?.filter(user => {
    const actionDate = dateFormat(new Date(user.createdAt));
    return (!dateRange.fromDate || actionDate >= dateRange.fromDate) && (!dateRange.toDate || actionDate <= dateRange.toDate);
  }) || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const filteredRequests = requestData ? requestData.filter(request => request.requestStatus === "INTERESTED") : [];

  function supplyRequest() {
    setIsLoading(true);
  }

  const fetchSupplies = () => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/supplies`)
      .then(res => {
        setSupplyList(res.data.content);
        setSupplyListUnFiltered(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(error => {
        console.error('Error fetching supplies:', error);
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    if (!buyerId) return;

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/buyer/` + buyerId)
      .then(res => {
        setBuyer(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [buyerId]);

  useEffect(() => {
    fetchSupplies();
  }, []);

  useEffect(() => {
    if (!selectedData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/activity-log` + '?objectId=' + selectedData.id + `&order=DESC&pageSize=10&pageIndex=0`)
      .then(res => {
        setSupplyLogList(res.data.content.content);
      }).catch(error => {
      console.error("Error fetching logs", error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [selectedData]);

  const fetchInterestRequest = () => {
    if (!viewData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/supply/` + viewData.id + `/request`)
      .then(res => {
        setRequestData(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  };

  useEffect(() => {
    fetchInterestRequest();
  }, [viewData]);

  const fetchMatchData = () => {
    if (!viewData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/matched-demands?supplyId=` + viewData.id)
      .then(res => {
        setMatchData(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  };

  useEffect(() => {
    fetchMatchData();
  }, [viewData]);

  useEffect(() => {
    if (!farmId) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(res => {
        setSelectedSupplierData(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  }, [farmId]);

  useEffect(() => {
    if (!viewData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + viewData.farmId + `/supply/` + viewData.id + `/orders`)
      .then(res => {
        setCreatedOrderData(res.data.content);
        setOrderCreated(false);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  }, [viewData, orderCreated]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/ccr-users`)
      .then(res => {
        setCcrList(res.data.content);
        const filtered = res.data.content.map(role => role.lastName);
        setFilteredCcrNames(filtered);
      }).catch(error => {
      console.error("Error fetching users", error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/supply/` + values.supplyId + '?ccr=' + values.ccr)
      .then(res => {
        toast.success("CCR assigned successfully");
        setSupplyList(supplyList.map(supply => {
          if (supply.id === values.supplyId) {
            return {...supply, ccr: values.ccr};
          }
          return supply;
        }));
        resetFormData()
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
      });

  }, [isLoading]);

  const handleSort = (selectedValue) => {
    const order = sortField === selectedValue ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(selectedValue);
    setSortOrder(order);
    setCol(selectedValue);
    setOrder(sortField === selectedValue ? (sortOrder === 'asc' ? 2 : 1) : 1);

    const sortedList = [...requestData];
    const sortedMatchList = [...matchData];
    if (selectedValue === 'quantity') {
      sortedList.sort((a, b) => order === 'asc' ? a.quantity - b.quantity : b.quantity - a.quantity);
    } else if (selectedValue === 'price') {
      sortedList.sort((a, b) => order === 'asc' ? a.price - b.price : b.price - a.price);
    } else if (selectedValue === 'demandQuantity') {
      sortedMatchList.sort((a, b) => order === 'asc' ? a.availableStock - b.availableStock : b.availableStock - a.availableStock);
    } else if (selectedValue === 'demandPrice') {
      sortedMatchList.sort((a, b) => order === 'asc' ? a.price - b.price : b.price - a.price);
    }

    setRequestData(sortedList);
    setMatchData(sortedMatchList);
  };

  const handleCreateOrder = (requestId) => {
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/request/' + requestId)
      .then(res => {
        toast.success("Order created successfully");
        setRequestData(prevData => prevData.filter(request => request.id !== requestId));
        setOrderCreated(true);
        setViewData({
          ...viewData,
          availableStock: viewData.availableStock - requestData.find(request => request.id === requestId).quantity
        });
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        fetchSupplies();
      });
  };

  const handleCreateMatchOrder = (demand) => {
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/supply/' + viewData.supplyId + '/demand/' + demand.demandId + '/create-order', {
      quantity: demand.availableStock,
      price: demand.price,
      interestDeadline: demand.neededDeadline,
      qualityParameters: demand.qualityParameters
    })
      .then(res => {
        toast.success("Order created successfully");
        setMatchData(prevData => prevData.filter(demand => demand.demandId !== demand.demandId));
        setOrderCreated(true);
        setViewData({...viewData, availableStock: viewData.availableStock - demand.availableStock});
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        fetchSupplies();
      });
  };

  function resetFormData() {
    setIsModalVisible(false);
    setFormErr("");
    initForm({})
    Object.keys(errors).forEach(key => delete errors[key]);
  }

  const fieldsToFilter = ['item'];

  const {
    handleSearch,
    filteredList,
    handleFilter,
    filters
  } = FilterHandler(setFilteredList, supplyListUnFiltered, fieldsToFilter);

  function setFilteredList() {
    setSupplyList(filteredList);
    setSupplyListUnFiltered(supplyListUnFiltered);
  }

  function handleView(log) {
    setSelectedItem(JSON.parse(log.object));
  }

  function getValue(key) {
    if (typeof selectedItem[key] === 'object') {
      return "OBJECT";
    }
    return formatDisplayEnumValue(selectedItem[key]);
  }

  const handleClose = () => {
    if (isLogVisible) {
      setIsLogVisible(false);
    }
    if (isRequestViewVisible) {
      setIsRequestViewVisible(false);
    }
    if (isCallVisible) {
      setIsCallVisible(false);
    }
    if (isRequestCall) {
      setIsRequestCall(false);
    }
    if (isMatchCall) {
      setIsMatchCall(false);
    }
  };

  return (
    <div>
      <Container>
      <div className="single-card overflow-visible m-rl-m-8  p-a-16">
      <div className={'sa-table-flex sa-table-position'} style={{marginBottom: 8}}>
        <span hidden={isViewVisible}>
          <span className="sa-table-btn-mute sa-search-align sa-table-float-left">
                <input
                  className={'sa-table-search sa-table-search-point font-14'}
                  onChange={handleSearch}
                  autoComplete={"off"}
                  type="text"
                  placeholder={t("placeHolder.SEARCH")}
                  name="search"
                />
                <FeatherIcon className={"sa-search-icon"} icon={"search"} width={'16px'}/>
            </span>
            <span className={"sa-table-daterange-hide sa-table-float-left"} style={{marginLeft: '5px'}}>
              <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                               onChange={(e) => onCheck(e)}
                               oneTap={false} onOk={(e) => onCheck(e)}
                               showWeekNumbers={true} appearance={"default"}
                               placeholder={t("title.LAST_WEEK")}
                               ranges={[{
                                 label: 'Today',
                                 value: [new Date(), new Date()]
                               }, {
                                 label: 'Yesterday',
                                 value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                               }, {
                                 label: 'Last 7 days',
                                 value: [dateFns.subDays(new Date(), 6), new Date()]
                               }, {
                                 label: 'Last 30 days',
                                 value: [dateFns.subDays(new Date(), 30), new Date()]
                               }]}
              />
            </span>
        </span>
        </div>
        <div className="sa-table-container ccr-user-calc-height" hidden={isViewVisible}>
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.SUPPLY_ID")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.PRODUCT")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.QUANTITY")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.PRICE")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.EXPECTED_HARVESTING_DATE")}</th>
              {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
              <th className="sa-table-item-align sa-table-head-sticky"></th>
              {/* } */}
            </tr>
            </thead>
            <tbody>
            {filteredSupplyList.length > 0 && (
              filteredSupplyList.sort((a, b) => {
                return b.actionAt === a.actionAt
                  ? new Date(b.createAt) - new Date(a.createAt)
                  : new Date(b.actionAt) - new Date(a.actionAt);
              }).map((supply, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}>
                    <FeatherIcon
                      icon={supply.supplyStatus === "SUPPLY_CONFIRMED" ? "check" : supply.supplyStatus === "SUPPLY_DECLINED" ? "x" : null}
                      className={`sa-table-arrow ${supply.supplyStatus === "SUPPLY_CONFIRMED" ? "text-green" : (supply.supplyStatus === "SUPPLY_DECLINED" ? "text-red" : null)}`}/>{supply.supplyId}
                  </td>
                  <td className={'sa-table-data'}>{toCapsFirst(supply.item)}</td>
                  <td
                    className={'sa-table-data'}>{(supply.unit && supply.unit !== "None") ? supply.availableStock + ` ` + supply.unit : supply.availableStock}</td>
                  <td className={'sa-table-data'}>Rs. {supply.price}</td>
                  <td className={'sa-table-data'}>{supply.expectedHarvestingDate}</td>
                  {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
                  <td className="sa-table-item-align">
                    <div className={'sa-table-action-flex'}>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.PHONE_CALL")}
                           onClick={() => {
                             setIsCallVisible(true);
                             Object.keys(errors).forEach(k => errors[k] = "");
                             values.supplyID = supply.id;
                             setSelectedData(supply);
                             setFormErr("");
                           }}><FeatherIcon className={"table-action"}
                                           icon={"phone-call"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                           title={supply.ccr ? t("button.ASSIGNED") : t("button.ASSIGN")}>
                        <FeatherIcon
                          onClick={() => {
                            setIsModalVisible(true);
                            Object.keys(errors).forEach(k => errors[k] = "");
                            values.supplyId = supply.id;
                            values.ccr = supply.ccr;
                            values.supplyID = supply.supplyId;
                            setCCRValue(values.ccr);
                            setFormErr("");
                          }}
                          className={`table-action ${supply.ccr ? "text-green" : null}`} icon={"user-check"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                           title={t("title.DEMAND_REQUESTS")}
                           onClick={() => {
                             setViewData(supply);
                             setIsRequestViewVisible(true);
                           }}>
                        <div className="icon-wrapper">
                          <span
                            className={`view-dot ${supply.requests ? "visible" : ''}`}>{supply.requests > 0 ? supply.requests : ''}</span>
                          <FeatherIcon className={"table-action"}
                                       icon={"user"}/>
                        </div>
                      </div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.INFO")}
                           onClick={() => {
                             setFarmId(supply.farmId);
                             setIsInfoVisible(true);
                           }}><FeatherIcon className={"table-action"}
                                           icon={"info"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.LOGS")}
                           onClick={() => {
                             setSelectedData(supply);
                             setIsLogVisible(true);
                           }}><FeatherIcon className={"table-action"}
                                           icon={"airplay"}/></div>
                    </div>
                  </td>
                  {/* } */}
                </tr>
              )))}
            </tbody>
          </table>
          {filteredSupplyList && filteredSupplyList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_SUPPLIES")}</div>
            </div>)
          }
        </div>
        {isViewVisible && <div>
          <div className={'d-flex align-items-center pdf-hide'}>
            <div className={"mb-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => {
                setIsViewVisible(false)
              }}
                           className={"sa-cursor"}/> {t("title.ORDERS")}
            </div>
          </div>
        </div>}
      </div>
      </Container>

      <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.ASSIGN_CCR")}</span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className={"col-11 informative-msg"}>
              <FeatherIcon className={"informative-msg-icon"} icon="alert-circle"/>
              <span className={"agronomy-practices-text"}>{t("info.CCR_SUPPLY_DETAILS")}<span
                style={{color: '#22C687'}}>{values.supplyID}</span> {t("info.CCR_DETAILS")}</span>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.CCR_USERNAME")}</label>
                  {!ccrValue ? (
                      <select
                        onBlur={handleOnBlur} onChange={handleChange}
                        value={values.ccr || ''} type="text"
                        className={`form-control mb-3 ${errors.ccr && "warning-input"}`}
                        name="ccr"
                        placeholder={t("placeHolder.SELECT_CCR_USERNAME")}
                      >
                        <option value={""} disabled>{t("placeHolder.SELECT_CCR_USERNAME")}</option>
                        {filteredCcrNames.map((username, index) => (
                          <option key={index} value={username}>{username}</option>
                        ))}
                      </select>
                    )
                    : (<input
                      value={values.ccr || ''} type="text"
                      className={`form-control ${errors.ccr && "warning-input"}`}
                      name="ccr" disabled
                    />)
                  }
                  {errors.ccr && (<p className="warning-input-msg m-0">{errors.ccr}</p>)}
                </div>
              </div>
            </div>

            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="sa-popup-btn d-flex ml-3 justify-content-end">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      style={{border: '1px solid #aaa', marginRight: '10px'}}
                      onClick={resetFormData}>{t("button.CANCEL")}
              </button>
              <button className={`sa-popup-secondary-btn-style ${ccrValue ? 'disable-btn' : ''}`} disabled={ccrValue}>
                {t("button.SAVE")}</button>
            </div>
          </form>
        </div>
      </div>

      <div
        className={"sa-popup-bg mobile-view-popup " + ((isLogVisible ? !isLogVisible : !isRequestViewVisible) && 'hide')}>
        <div className="sa-popup view-scroll">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              {isRequestViewVisible && (<span className={'sa-model-heading'}>{t("title.DEMAND_REQUEST")}</span>)}
              {isLogVisible && (<span className={'sa-model-heading'}>{t("title.ACTIVITY_LOG")}</span>)}
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {isRequestViewVisible && InformativeMSG(t("info.INTERESTED_IN_DEMAND_REQUEST"))}
            <div className="sa-popup-content m-b-16">
              {isRequestViewVisible && (
                <>
                  <div className="list-container">
                    <div className="single-card mb-0">
                      <FeatherIcon icon={"archive"} className={"sa-icon"}/>
                      <div className="card-header-text">
                        <div>
                          <label className="sub-text">{t("title.PRODUCT")}</label>
                          <div className="heading">{viewData.item}</div>
                        </div>
                      </div>
                    </div>
                    <div className="single-card mb-0">
                      <FeatherIcon icon={"calendar"} className={"sa-icon"}/>
                      <div className="card-header-text">
                        <div>
                          <label className="sub-text">{t("title.EXPECTED_HARVESTING_DATE")}</label>
                          <div className="heading">{viewData.expectedHarvestingDate}</div>
                        </div>
                      </div>
                    </div>
                    <div className="single-card mb-0">
                      <FeatherIcon icon={"user"} className={"sa-icon"}/>
                      <div className="card-header-text">
                        <div>
                          <label className="sub-text">{t("title.SUPPLY_ID")}</label>
                          <div className="heading">{viewData.supplyId}</div>
                        </div>
                      </div>
                    </div>
                    <div className="single-card mb-0">
                      <FeatherIcon icon={"package"} className={"sa-icon"}/>
                      <div className="card-header-text">
                        <div>
                          <label className="sub-text">{t("title.QUANTITY")}</label>
                          <div className="heading">{viewData.availableStock}</div>
                        </div>
                      </div>
                    </div>
                    <div className="single-card mb-0">
                      <FeatherIcon icon={"dollar-sign"} className={"sa-icon"}/>
                      <div className="card-header-text">
                        <div>
                          <label className="sub-text">{t("title.PRICE")}</label>
                          <div className="heading">{viewData.price}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-whole">
                    <p>{t("title.INTERESTED")}<span className="interest-line"></span></p>
                    <table className="table table-borderless sa-table-width">
                      <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{t("title.INTEREST_STATUS")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.BUYER_NAME")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.PHONE_NO")}</th>
                        <th className="sa-table-head-sticky" onClick={() => handleSort('price')}>
                          {t("title.PRICE")} (per kg)
                          <span>
                              {(order === 0 || col !== 'price') &&
                                <img className="short-option-style" src={sort} alt="Sort"/>}
                            {order === 1 && col === 'price' &&
                              <img className="short-option-style" src={asc} alt="Ascending"/>}
                            {order === 2 && col === 'price' &&
                              <img className="short-option-style" src={dsc} alt="Descending"/>}
                          </span>
                        </th>
                        <th className="sa-table-head-sticky" onClick={(e) => handleSort('quantity')}>
                          {t("title.DEMAND_QUANTITY")}<span>
                            {(order === 0 || col !== 'quantity') &&
                              <img className="short-option-style" src={sort} alt="Sort"/>}
                          {order === 1 && col === 'quantity' &&
                            <img className="short-option-style" src={asc} alt="Ascending"/>}
                          {order === 2 && col === 'quantity' &&
                            <img className="short-option-style" src={dsc} alt="Descending"/>}
                        </span>
                        </th>
                        <th className={"sa-table-head-sticky"}>{t("title.NEEDED_DEADLINE")}</th>
                        {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
                        <th className="sa-table-item-align sa-table-head-sticky"></th>
                        {/* } */}
                      </tr>
                      </thead>
                      <tbody>
                      {filteredRequests.length > 0 ? (
                        filteredRequests.map((request, index) => (
                          <tr key={index}>
                            <td className={'sa-table-data'}>{formatDisplayEnumValue(request.requestStatus)}</td>
                            <td className={'sa-table-data'}>{toCapsFirst(request.buyerInfo.lastName)}</td>
                            <td className={'sa-table-data'}>{request.email ? request.buyerInfo.email : 'NA'}</td>
                            <td className={'sa-table-data'}>{request.phoneNo ? request.buyerInfo.contactNo : 'NA'}</td>
                            <td className={'sa-table-data'}>Rs. {request.price}</td>
                            <td
                              className={'sa-table-data'}>{(request.unit && request.unit !== "None") ? request.quantity + ` ` + request.unit : request.quantity}</td>
                            <td className={'sa-table-data'}>{request.interestDeadline}</td>
                            {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
                            <td className="sa-table-item-align">
                              <div className={'sa-table-action-flex'}>
                                <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                     title={t("button.PHONE_CALL")}
                                     onClick={() => {
                                       setIsRequestCall(true);
                                       Object.keys(errors).forEach(k => errors[k] = "");
                                       values.id = request.id;
                                       setIsRequestViewVisible(false);
                                       setFormErr("");
                                       setSelectedData(request);
                                     }}><FeatherIcon
                                  className={`table-action ${request.demandStatus === "DEMAND_CONFIRMED" ? "text-green" : (request.demandStatus === "DEMAND_DECLINED" ? "text-red" : null)}`}
                                  icon={"phone-call"}/></div>
                                <button
                                  className={`sa-popup-secondary-btn-style ${viewData.availableStock < request.quantity ? 'disable-btn' : ''} ${viewData.supplyStatus === "SUPPLY_CONFIRMED" && request.demandStatus === "DEMAND_CONFIRMED" ? '' : 'disable-btn'} `}
                                  style={{width: "120px"}}
                                  type="button"
                                  onClick={() => {
                                    setIsInterestOrderVisible(true);
                                    setIsRequestViewVisible(false);
                                    setSelectedData(request);
                                    setFarmId(request.supplierInfo.id);
                                    setBuyerId(request.buyerInfo.id);
                                  }}
                                  disabled={viewData.availableStock === 0 || viewData.availableStock < request.quantity || request.quantity === 0 || request.demandStatus !== "DEMAND_CONFIRMED" || viewData.supplyStatus !== "SUPPLY_CONFIRMED"}
                                >{t("button.CREATE_ORDER")}</button>
                              </div>
                            </td>
                            {/* } */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <div className={"empty-results"} style={{padding: '5px'}}>
                              <FeatherIcon icon="info"/>
                              <div
                                className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_INTERESTED_REQUESTS")}</div>
                            </div>
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-whole">
                    <p style={{color: 'red'}}>{t("title.POSSIBLE_MATCHES")} <span className="not-interest-line"></span>
                    </p>
                    <table className="table table-borderless sa-table-width">
                      <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{t("title.DEMAND_ID")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.DEMAND_STATUS")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.PHONE_NO")}</th>
                        <th className={"sa-table-head-sticky"}
                            onClick={(e) => handleSort('demandPrice')}>{t("title.PRICE")}{`(per kg)`}
                          <span>
                          {(order === 0 || col !== 'demandPrice') &&
                            <img className="short-option-style" src={sort} alt="Sort"/>}
                            {order === 1 && col === 'demandPrice' &&
                              <img className="short-option-style" src={asc} alt="Ascending"/>}
                            {order === 2 && col === 'demandPrice' &&
                              <img className="short-option-style" src={dsc} alt="Descending"/>}
                        </span>
                        </th>
                        <th className="sa-table-head-sticky"
                            onClick={(e) => handleSort('demandQuantity')}>{t("title.DEMAND_QUANTITY")}<span>
                          {(order === 0 || col !== 'demandQuantity') &&
                            <img className="short-option-style" src={sort} alt="Sort"/>}
                          {order === 1 && col === 'demandQuantity' &&
                            <img className="short-option-style" src={asc} alt="Ascending"/>}
                          {order === 2 && col === 'demandQuantity' &&
                            <img className="short-option-style" src={dsc} alt="Descending"/>}
                        </span>
                        </th>
                        <th className={"sa-table-head-sticky"}>{t("title.NEEDED_DEADLINE")}</th>
                        <th className="sa-table-item-align sa-table-head-sticky"></th>
                      </tr>
                      </thead>
                      <tbody>
                      {matchData && matchData.length > 0 && matchData.map((demand, index) => (
                        <tr key={index}>
                          <td className={'sa-table-data'}>{demand.demandId}</td>
                          <td className={'sa-table-data'}>{formatDisplayEnumValue(demand.demandStatus)}</td>
                          <td className={'sa-table-data'}>{demand.email ? demand.buyerInfo.email : 'NA'}</td>
                          <td className={'sa-table-data'}>{demand.phoneNo ? demand.buyerInfo.phoneNo : 'NA'}</td>
                          <td className={'sa-table-data'}>Rs. {demand.price}</td>
                          <td
                            className={'sa-table-data'}>{(demand.unit && demand.unit !== "None") ? demand.availableStock + ` ` + demand.unit : demand.availableStock}</td>
                          <td className={'sa-table-data'}>{demand.neededDeadline}</td>
                          <td className="sa-table-item-align">
                            <div className={'sa-table-action-flex'}>
                              <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                   title={t("button.PHONE_CALL")}
                                   onClick={() => {
                                     setIsMatchCall(true);
                                     Object.keys(errors).forEach(k => errors[k] = "");
                                     values.id = demand.id;
                                     setIsRequestViewVisible(false);
                                     setFormErr("");
                                     setSelectedData(demand);
                                   }}><FeatherIcon
                                className={`table-action ${demand.demandStatus === "DEMAND_CONFIRMED" ? "text-green" : null}`}
                                icon={"phone-call"}/></div>
                              <button
                                className={`sa-popup-secondary-btn-style ${viewData.supplyStatus === "SUPPLY_CONFIRMED" && demand.demandStatus === "DEMAND_CONFIRMED" ? '' : 'disable-btn'}`}
                                style={{width: "120px"}}
                                type="button"
                                onClick={() => {
                                  setIsMatchOrderVisible(true);
                                  setIsRequestViewVisible(false);
                                  setSelectedData(demand);
                                  setFarmId(viewData.farmId);
                                  setBuyerId(demand.buyerId);
                                }}
                                disabled={demand.demandStatus !== "DEMAND_CONFIRMED" || viewData.supplyStatus !== "SUPPLY_CONFIRMED"}
                              >{t("button.CREATE_ORDER")}</button>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {matchData && matchData.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <div className={"empty-results"} style={{padding: '5px'}}>
                              <FeatherIcon icon="info"/>
                              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_MATCH_MAKING")}</div>
                            </div>
                          </td>
                        </tr>)}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-whole">
                    <p style={{color: 'red'}}>{t("title.CREATED_ORDERS")}<span className="not-interest-line"></span></p>
                    <table className="table table-borderless sa-table-width">
                      <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{t("title.ORDER_ID")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.ORDER_STATUS")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.BUYER")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.PRICE")}{`(per kg)`}</th>
                        <th className="sa-table-head-sticky">{t("title.QUANTITY")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.NEEDED_DEADLINE")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {createdOrderData && createdOrderData.length > 0 && createdOrderData.map((order, index) => (
                        <tr key={index}>
                          <td className={'sa-table-data'}>{order.orderId}</td>
                          <td className={'sa-table-data'}>{formatDisplayEnumValue(order.requestStatus)}</td>
                          <td className={'sa-table-data'}>{toCapsFirst(order.buyerInfo.lastName)}</td>
                          <td className={'sa-table-data'}>Rs. {order.price}</td>
                          <td
                            className={'sa-table-data'}>{(order.unit && order.unit !== "None") ? order.quantity + ` ` + order.unit : order.quantity}</td>
                          <td className={'sa-table-data'}>{order.interestDeadline}</td>
                        </tr>
                      ))}
                      {createdOrderData && createdOrderData.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <div className={"empty-results"} style={{padding: '5px'}}>
                              <FeatherIcon icon="info"/>
                              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_CREATED_ORDER")}</div>
                            </div>
                          </td>
                        </tr>)}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {isLogVisible && (
                <>
                  <div style={{paddingLeft: '10px'}}>{t("title.CCR")}
                    : {(selectedData.ccr ? toCapsFirst(selectedData.ccr) : "NA")}</div>
                  <table className="table table-borderless sa-table-width">
                    <thead>
                    <tr>
                      <th className={"sa-table-head-sticky"}>{t("title.TIME")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.TYPE")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.ACTION_BY")}</th>
                      <th className="sa-table-head-sticky">{t("title.DESCRIPTION")}</th>
                      <th className="sa-table-item-align sa-table-head-sticky">{t("title.ACTION")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {supplyLogList && supplyLogList.length > 0 && supplyLogList.map((log, index) => (
                      <tr key={index}>
                        <td className={'sa-table-data'}>{log.time}</td>
                        <td className={'sa-table-data'}>{log.type}</td>
                        <td className={'sa-table-data'}>{log.userInfo.lastName}</td>
                        <td className={'sa-table-data'}>{log.description}</td>
                        <td className="sa-table-item-align">
                          <div className={'sa-table-action-flex'}>
                            <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title="View">
                              <FeatherIcon
                                onClick={() => {
                                  handleView(log);
                                }}
                                className="table-action" icon={"eye"}/></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  {supplyLogList && supplyLogList.length === 0 && (<div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ACTIVITIES")}</div>
                  </div>)}
                </>
              )}
            </div>
            {isRequestViewVisible && (
              <div className="sa-popup-btn">
                <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        style={{border: '1px solid #aaa'}}
                        onClick={() => setIsRequestViewVisible(false)}>{t("button.CANCEL")}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>

      <div className={"sa-popup-bg " + (!isInfoVisible && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={() => setIsInfoVisible(false)}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="content-view">
                  <label>{t("title.SUPPLIER")}</label>
                  <div className="view-data"
                       style={{backgroundColor: '#fff', border: '#ededed 1px solid'}}>{selectedSupplierData.name}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-view">
                  <label>{t("title.SUPPLIER_EMAIL")}</label>
                  <div className="view-data" style={{
                    backgroundColor: '#fff',
                    border: '#ededed 1px solid'
                  }}>{selectedSupplierData.contactEmail ? selectedSupplierData.contactEmail : "NA"}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-view">
                  <label>{t("title.PHONE")}</label>
                  <div className="view-data" style={{
                    backgroundColor: '#fff',
                    border: '#ededed 1px solid'
                  }}>{selectedSupplierData.contactPhoneNumber ? selectedSupplierData.contactPhoneNumber : "NA"}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-view">
                  <label>{t("title.LOCATION")}</label>
                  <div className="view-data" style={{
                    backgroundColor: '#fff',
                    border: '#ededed 1px solid'
                  }}>{selectedSupplierData.location}</div>
                </div>
              </div>
            </div>

            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      style={{border: '1px solid #aaa'}}
                      onClick={() => setIsInfoVisible(false)}>{t("button.CANCEL")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/*-----------------------------------------------------------------View AuditLogPopup Details---------------------------------------------------            */}
      {selectedItem && <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.VIEW_AUDIT_LOG_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={() => {
                setSelectedItem(null)
              }}><FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className="sa-popup-content">
              {Object.entries(selectedItem).map(([key, value]) =>
                (<div className="col-md-6" key={key + "archive"}>
                  <div className="form-group m-b-16 view-popup-label">
                    <label className={"labelSize"}>{camelToReadable(key)} </label>
                    <label className="lable-design">
                      <label className="labelSize1 limit-charater-xindicateViewLabel"
                             title={getValue(key)}>{getValue(key)}</label></label>
                  </div>
                </div>)
              )}
            </div>
          </form>
        </div>
      </div>}

      {isCallVisible && (
        <SupplyCallHistory isCallVisible={isCallVisible}
                           handleClose={handleClose}
                           supplyID={values.supplyID}
                           reUpdate={fetchSupplies}
                           selectedData={selectedData}
        />
      )}
      {isRequestCall && (
        <SupplyRequestCall isRequestCall={isRequestCall}
                           handleClose={() => {
                             setIsRequestCall(false);
                             setIsRequestViewVisible(true);
                           }}
                           requestId={values.id}
                           viewData={viewData}
                           reUpdate={fetchInterestRequest}
                           selectedData={selectedData}
        />
      )}
      {isMatchCall && (
        <DemandCallHistory isCallVisible={isMatchCall}
                           handleClose={() => {
                             setIsMatchCall(false);
                             setIsRequestViewVisible(true);
                           }}
                           demandID={values.id}
                           reUpdate={fetchMatchData}
                           selectedData={selectedData}
        />
      )}
      {isInterestOrderVisible && (
        <SupplyCreateOrderDetail isInterestOrderVisible={isInterestOrderVisible}
                                 handleClose={() => {
                                   setIsInterestOrderVisible(false);
                                   setIsRequestViewVisible(true);
                                 }}
                                 viewData={viewData}
                                 handleCreateOrder={handleCreateOrder}
                                 selectedData={selectedData}
                                 selectedSupplierData={selectedSupplierData}
                                 buyerData={buyer}
        />
      )}
      {isMatchOrderVisible && (
        <SupplyCreateOrderDetail isMatchOrderVisible={isMatchOrderVisible}
                                 handleClose={() => {
                                   setIsMatchOrderVisible(false);
                                   setIsRequestViewVisible(true);
                                 }}
                                 viewData={viewData}
                                 handleCreateOrder={handleCreateMatchOrder}
                                 selectedData={selectedData}
                                 selectedSupplierData={selectedSupplierData}
                                 buyerData={buyer}
        />
      )}
    </div>
  )
};
export default SupplyRequests
