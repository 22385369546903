import React, {useState} from "react";
import FeatherIcon from "feather-icons-react";

const Popup = ({children, title}) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <div onClick={togglePopup} className="mb-3">{<button className="btn btn-sa-primary ml-5">{title}</button>}</div>
      {showPopup && (
        <React.Fragment>
          <div className="sa-popup-bg" onClick={closePopup}></div>
          <div className="popup-container">
            <div className="sa-popup-close-icon" onClick={closePopup}>
              <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
            </div>
            {children}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Popup;