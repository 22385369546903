import React from "react";
import image from "../../images/company.jpg";
import FeatherIcon from "feather-icons-react";
import {useHistory} from "react-router";
import {isSuper} from "../../shared/utils/SharedAuthentication";

const BuyerItem = (props) => {
  const history = useHistory()

  function goTo() {
    history.push("/buyer/" + props.buyer.id + "/buyer-details")
  }

  return (
    <div className="single-card ma-res-bot sa-cursor" id={"corporate-card"}>
      <div className={"single-card-header"} id={"corporate-card-header"}>
        <div onClick={(e) => {
          if (e.target.parentNode.id === "corporate-card" || e.target.parentNode.id === "corporate-card-header" || e.target.parentNode.id === "farmImg") {
            goTo()
          }
        }}>
          <div className={"sa-corporate-action-flex"}>
            <div className="corporate-action-icon" id={"corporate-edit"}>
              <FeatherIcon
                onClick={(e) => {
                  props.onEdit(props.buyer)
                }}
                className={"corporate-action-icon-size"}
                icon={"edit"}
              />
            </div>
            {isSuper() && <div className="corporate-action-icon" id={"corporate-delete"}>
              <FeatherIcon
                onClick={(e) => {
                  props.onDelete(props.buyer.id)
                }}
                className={"corporate-delete-icon"}
                icon={"trash-2"}
              />
            </div>}
          </div>
          <div className="card-img-view" id={"farmImg"}>
            <img src={props.buyer.companyLogo ? props.buyer.companyLogo : image} className="card-img" alt="corporate"/>
          </div>
          <div className="card-header-text limit-charater-farmname">
            <label className="heading">{props.buyer.companyName}</label>
            <div title={"contactName"} className="sub-text">
              {props.buyer.contactName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerItem;
