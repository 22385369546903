import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import * as _ from "underscore";
import {isEqual} from "underscore";
import FormHandler from "../../../shared/utils/FormHandler";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {toCapsFirst, uploadImage} from "../../../utils/Utils";
import {validatePlotInfo} from "../../../utils/FormValidationRules";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import {getSelectedPlot} from "../../../actions/plot";
import {toast} from "react-toastify";
import PlotCultivation from "./plot-cultivation";
import imageCompression from 'browser-image-compression';
import farmImg from '../../../images/farm.jpg';
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import MapComponent from "../../smart-farm/MapComponent";


const PlotInfo = () => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {plotId} = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errMap, setErrMap] = useState(null);
  const [updateImg, setUpdateImg] = useState(false);
  const [thumbnail, setThumbnail] = useState(false);
  const [optionsArray, setOptionsArray] = useState([]);
  const [isEditGeo, setIsEditGeo] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [updatedCoordinates, setUpdatedCoordinates] = useState([]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    handleKeyDown,
  } = FormHandler(plotInfo, validatePlotInfo);

  function plotInfo() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    const payload = _.pick(values, ['area', 'coordinates', 'crop', 'farmId', 'id', 'name']);

    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId, payload)
      .then(function (response) {
        setIsEdit(false);
        dispatch(getSelectedPlot(response.data.content));
        toast.success(t("successMsg.PLOT_INFORMATION_UPDATED_SUCCESSFULLY"));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [isLoading]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(
      process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`
    )
      .then((res) => {
        let cropNames = _.pluck(res.data.content, 'cropName')
        setOptionsArray(cropNames);

      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  useEffect(() => {
    if (!values.id)
      for (let key of Object.keys(selectedPlot)) {
        values[key] = selectedPlot[key];
      }
  });

  function deleteFarm() {
    dispatch(toggleConfirmationDialog({isVisible: true}));
  }

  function onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      imageCompression(imageFile, options)
        .then((compressedFile) => {
        })
        .catch((error) => {

        });
      const reader = new FileReader();
      reader.onload = (e) => {
        values.image = e.target.result;

      };
      reader.readAsDataURL(imageFile);
    }
  }

  // useEffect(() => {
  //     if (!confirmationDialog || !confirmationDialog.onSuccess) {
  //         return;
  //     }
  //     axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId)
  //         .then(function (response) {
  //                         dispatch(resetConfirmationDialog());
  //                         history.push(`/${farmId}/plot`)

  //         })
  //         .catch(function (error) {
  //                     dispatch(resetConfirmationDialog());
  //         })
  // }, [confirmationDialog]);


  function formCancel(e) {
    setIsEdit(false);
    e.preventDefault();
    for (let key of Object.keys(values)) {
      errors[key] = "";
      if (selectedPlot[key]) {
        values[key] = selectedPlot[key];
      } else {
        values[key] = "";
      }
    }
    setError(null);
  }

  // useEffect(() => {
  //     return () => {
  //         if (history.action === "POP")
  //
  //         history.push(`/${farmId}/plot`);
  //     }
  // },[history]);
  function scrollToInfo() {
    let elem = document.getElementById("scroll");
    elem.scrollIntoView();
  }

  useEffect(() => {
    if (!updateImg || !thumbnail) {
      return
    }
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId, values)
      .then(res => {
        dispatch(getSelectedPlot(res.data.content))
      }).finally(() => {
      dispatch(toggleLoader(false))
      setUpdateImg(false);
      setThumbnail(false);
    })


  }, [updateImg, thumbnail]);

  async function uploadFile(e) {
    e.persist();
    dispatch(toggleLoader(true))
    const imageFile = e.target.files[0];
    const thumbnail = "thumbnail_" + new Date().getTime() + '.jpeg';
    const imageName = "image_" + new Date().getTime() + '.jpeg';


    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 96,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(async function (compressedFile) {

        let response = await uploadImage(thumbnail, compressedFile);
        values.imageThumbnail = response;
        setThumbnail(true)

      })
      .catch(function (error) {

      });

    let response = await uploadImage(imageName, imageFile);
    values.image = response;
    setUpdateImg(true)

  }

  useEffect(() => {
    setCoordinates(selectedPlot.coordinates)
  }, [selectedPlot])

  function updateLocation() {
    if (isEqual(updatedCoordinates, coordinates)) {
      toast.error("No Changes Found")
      return
    } else if (errMap) {
      toast.error(errMap)
      return
    }
    values.coordinates = updatedCoordinates
    dispatch(toggleLoader(true))
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId, values)
      .then(res => {
        dispatch(getSelectedPlot(res.data.content))
        toast.success("Successfully Updated Coordinates")
      }).finally(() => {
      dispatch(toggleLoader(false))
      setUpdateImg(false);
      setThumbnail(false);
      setIsEditGeo(false)
    })
  }

  console.log(values)


  return (
    <div className="">
      <Container>
        {!isEdit ?
          <div className="single-card m-rl-m-8 p-a-16">
            {hasPermission("SETTINGS.FARM.PLOTS.PLOT_INFO", "WRITE") && <div>
              {!isEditGeo ? <div className="pull-right hide-425">
                  <button className="btn btn-sa-secondary edit-btn-color w-140p" onClick={() => {
                    setIsEditGeo(true);
                  }}><FeatherIcon className={"text-dark-gray m-r-8"} icon={"edit-2"}
                                  width={'20px'}/>{t("button.EDIT_GEO")}
                  </button>
                  <button className="btn btn-sa-secondary edit-btn-color w-140p ml-2" onClick={() => {
                    setIsEdit(true);
                    scrollToInfo()
                  }}><FeatherIcon className={"text-dark-gray m-r-8"} icon={"edit-2"}
                                  width={'20px'}/>{t("button.EDIT")}
                  </button>
                  <button className="btn btn-sa-icon m-r-40" onClick={deleteFarm}><FeatherIcon
                    className={"text-red icon-in-btn"} icon={"trash-2"}/><span
                    className={'hide-425'}>{t("title.DELETE_PLOT")}</span>
                  </button>
                </div> :
                <div className="pull-right hide-425">
                  <button className="btn btn-sa-secondary w-140p m-r-8"
                          onClick={(e) => setIsEditGeo(false)}>{t("button.CANCEL")}</button>
                  <button className="btn btn-sa-secondary w-140p"
                          onClick={updateLocation}>{t("button.SAVE")}</button>
                </div>}
            </div>
            }
            <div className="card-img-view img-center-768" style={{overflow: 'visible'}}>
              <img className="card-img"
                   src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image ? selectedPlot.image : farmImg}
                   alt="farm" style={{borderRadius: '8px'}}/>
              <label htmlFor="file-upload" className="farm-img-edit">
                <FeatherIcon className={"text-gray"} icon={"edit"} width={'20px'}/>
              </label>
              <input id="file-upload" type="file" onChange={(e) => uploadFile(e)}/>
            </div>
            {isEditGeo && <div className={"my-5"}>
              <MapComponent coordinate={coordinates}
                            updatedCoordinates={(e) => setUpdatedCoordinates(e)}
                            setErrMap={(e) => setErrMap(e)}
                            isEditPlot={true}/>
            </div>}
            <div id="scroll">
              <div className={"farm-info-heading"}>
                <div className={"farm-info-heading-set"}>{t("title.PLOT_INFORMATION")}</div>
              </div>
              <div className={"row farm-info-set"}>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.PLOT_NAME")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.name ? toCapsFirst(selectedPlot.name) : "NA"}</div>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.CROP")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.crop ? selectedPlot.crop : "NA"}</div>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.AREA")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.area ? selectedPlot.area : "NA"}</div>
                </div>
              </div>
            </div>
            <div className="m-t-26 float-r show-425 m-r-0">
              <button className="btn btn-sa-secondary edit-btn-color btn-moblie-veiw edit-btn-moblie-veiw"
                      onClick={() => {
                        setIsEdit(true);
                        scrollToInfo()
                      }}>
                <FeatherIcon className={"text-dark-gray"} icon={"edit-2"} width={'20px'}/></button>
              <button className="btn btn-sa-icon btn-moblie-veiw m-r-8" onClick={deleteFarm}><FeatherIcon
                className={"text-red icon-in-btn"} icon={"trash-2"}/>
              </button>
            </div>
          </div>
          :
          <div className="single-card m-rl-m-8 p-a-16">
            <form onSubmit={handleSubmit} noValidate>
              <div className="pull-right m-t-26 hide-768">
                <button className="btn btn-sa-secondary w-140p m-r-8"
                        onClick={(e) => formCancel(e)}>{t("button.CANCEL")}</button>
                <button className="btn btn-sa-secondary w-140p">{t("button.SAVE")}</button>
              </div>
              <div className="card-img-view img-center-768">
                <img className="card-img"
                     src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image ? selectedPlot.image : farmImg}
                     alt="farm"/>
              </div>
              <div className={"farm-info-heading"}>
                <div className={"farm-info-heading-set"}>{t("title.PLOT_INFORMATION")}</div>
              </div>
              <div className={"row farm-info-set"}>
                <div className={"col-md-6 col-lg-3 m-b-30-info"}>
                  <div className={"farm-info-title"}>{t("title.PLOT_NAME")}</div>
                  <input type="text" className={errors.name ? "form-control warning-input" : "form-control"}
                         value={values.name} onChange={handleChange} name="name"
                         onBlur={handleOnBlur} placeholder={t("title.PLOT_NAME")}/>
                  {errors.name && (
                    <p className="warning-input-msg">{errors.name}</p>)}
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.CROP")}</div>
                  <select
                    onBlur={handleOnBlur} onChange={handleChange}
                    value={values.crop || ''} type="text"
                    className={`form-control px-1 ${errors.crop && "warning-input"}`}
                    name="crop"
                    placeholder={t("title.CROP")}
                  >
                    {optionsArray.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.AREA")}</div>
                  <input type="number" className={errors.area ? "form-control warning-input" : "form-control"}
                         onChange={handleChange} value={values.area} name="area"
                         onBlur={handleOnBlur} onKeyDown={handleKeyDown} placeholder={t("title.AREA")}/>
                  {errors.area && (
                    <p className="warning-input-msg">{errors.area}</p>)}
                </div>
              </div>
              {error && (<p>{error}</p>)}
              <div className="m-t-26 show-768">
                <button className="btn btn-sa-secondary w-100pe m-b-16">{t("button.SAVE")}</button>
                <button className="btn btn-sa-secondary w-100pe"
                        onClick={(e) => formCancel(e)}>{t("button.CANCEL")}</button>
              </div>
            </form>
          </div>
        }
      </Container>
      <PlotCultivation cropName={selectedPlot.crop}/>
    </div>
  )
};


export default PlotInfo;

