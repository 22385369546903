import React, {useEffect} from "react";
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toggleLoader} from "../../../shared/actions/setting";
import {getPlotIrrigationStatus} from "../../../actions/plot";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {ACTUATOR_TYPE, IRRIGATION_STATUS} from "../../../utils/Enum";

const PlotIrrigationStatus = (props) => {
  const dispatch = useDispatch();

  const plotIrrigationStatus = useSelector(state => {
    return state.plotList.plotIrrigationStatus
  });

  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + `/status`)
      .then(function (response) {
        if (response.data.content) {
          dispatch(getPlotIrrigationStatus(response.data.content));
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.irrigationId]);

  return (
    <div>
      {farmSource && farmSource.type === ACTUATOR_TYPE.TANK && (
        <div className={"ir-manual-ind"}>
          <div className={"ir-manual-ind-line-tank"}>
            {plotIrrigationStatus && plotIrrigationStatus.description
              ? plotIrrigationStatus.description
              : "-"}
            {plotIrrigationStatus && plotIrrigationStatus.status === IRRIGATION_STATUS.ON_REQUESTED
              ? (props.wateringTime ? ` ${props.wateringTime}` : '')
              : ''}
          </div>
          <div className={"ir-manual-ind-line-text"}>
            <FeatherIcon
              className={"ir-manual-icon-alert"}
              icon="alert-circle"
              style={{marginTop: '10px'}}
            />
          </div>
        </div>
      )}
    </div>
  );

};

export default PlotIrrigationStatus;
