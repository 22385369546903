import React from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import {hasPermission} from "../../../utils/Authentication";

const FarmTab = (props) => {
  const {t, i18n} = useTranslation();


  return (
    <div className="text-center tab-container m-rl-m-8 farm-tab-parent d-flex">
      {/*<NavLink to={'/' + props.farmId + '/dashboard'} activeClassName="plot-tog-active">*/}
      {/*  <div id="tabPlot" className="farm-header-toggles"><FeatherIcon className={"icon-in-tab farm-tab-icon-color-f"} icon={"archive"}/><span*/}
      {/*    className="farm-tab-farm"> Dashboard</span></div>*/}
      {/*</NavLink>*/}
      {hasPermission("SMART_FARM.PLOTS") &&
        <NavLink to={'/' + props.farmId + '/plot'} activeClassName="plot-tog-active">
          <div id="tabPlot" className="farm-header-toggles"><FeatherIcon className={"icon-in-tab farm-tab-icon-color-f"}
                                                                         icon={"layout"}/><span
            className="farm-tab-farm"> {t("title.PLOTS")}</span></div>
        </NavLink>
      }
      {hasPermission("SMART_FARM.IRRIGATION") &&
        <NavLink to={'/' + props.farmId + '/irrigation-setting'} activeClassName="plot-tog-active">
          <div id={'tabIrrigation'} className="farm-header-toggles"><FeatherIcon
            className={"icon-in-tab farm-tab-icon-color-f"} icon={"clock"}/><span
            className={"farm-tab-farm"}> {t("title.IRRIGATION")}</span></div>
        </NavLink>
      }
      {hasPermission("SMART_FARM.REPORTS") &&
        <NavLink to={'/' + props.farmId + '/report'} activeClassName="plot-tog-active">
          <div id={'tabReport'} className="farm-header-toggles"><FeatherIcon
            className={"icon-in-tab farm-tab-icon-color-f m-r-8"} icon={"clipboard"}/><span
            className={"farm-tab-farm"}>{t("title.REPORTS")}</span></div>
        </NavLink>
      }
      {hasPermission("SMART_FARM.SETTINGS") &&
        <NavLink to={'/' + props.farmId + '/config'} activeClassName="plot-tog-active">
          <div id={'tabSetting'} className="farm-header-toggles"><FeatherIcon
            className={"icon-in-tab farm-tab-icon-color-s"} icon={"settings"}/><span
            className={"farm-tab-setting"}>{t("title.SETTINGS")}</span></div>
        </NavLink>
      }
      {/*<NavLink to={'/' + props.farmId + '/info'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabFarmInfo'} className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"info"}/><span*/}
      {/*    className={"mobile-hide"}> Farm Info</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/settings'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabSetting'} className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"settings"}/><span*/}
      {/*    className={"mobile-hide"}> Settings</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/expense'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabSetting'} className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"settings"}/><span*/}
      {/*    className={"mobile-hide"}> Expense</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/income'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabSetting'} className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"settings"}/><span*/}
      {/*    className={"mobile-hide"}> Income</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/product'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabSetting'} className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"settings"}/><span*/}
      {/*    className={"mobile-hide"}> Product</span></div>*/}
      {/*</NavLink>*/}
      {/*  <NavLink to={'/' + props.farmId + '/cash-flow'} activeClassName="plot-tog-active">*/}
      {/*      <div className="farm-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"dollar-sign"}/>*/}
      {/*      <span className={"mobile-hide"}> Cash Flow</span></div>*/}
      {/*  </NavLink>*/}
    </div>
  )
};


export default FarmTab
