import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getColouredStatus, toCapsFirst} from "../../../utils/Utils";
import FarmTab from "./farm-tab";
import {getAllFarmSources, getFarmActuators, getFarmSource, getSelectedFarm} from "../../../actions/farm";
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {getPlotList, getSelectedPlot} from "../../../actions/plot";
import FeatherIcon from "feather-icons-react";
import {changeView, toggleLoader} from "../../../shared/actions/setting";
import {ACTUATOR_TYPE, VIEW} from "../../../utils/Enum";
import WeatherPop from "./farm-weather-pop";
import {useTranslation} from "react-i18next";
import {Img} from "react-image";
import farmImg from "../../../images/farm.jpg";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {isEmpty} from "../../../shared/utils/utils";

const FarmHeader = (props) => {
  const {t, i18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSource, setLoadingSource] = useState(true);
  const {farmId} = useParams();
  const [sensorDetails, setSensorDetails] = useState({});
  const [motorDetails, setMotorDetails] = useState({});
  const dispatch = useDispatch();

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  // useEffect(() => {
  //
  //
  //   return () => {
  //     if (history.action === "POP")
  //
  //     history.push(`/`);
  //   }
  // }, [history]);

  useEffect(() => {
    dispatch(changeView(VIEW.PLOT_LIST));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/valve')
      .then(response => {
        dispatch(getFarmActuators(response.data.content));
        dispatch(toggleLoader(false));
      })
      .catch(error => console.log(`Error ${error}`))
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/summary`)
      .then(function (response) {
        setSensorDetails(response.data.content)
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);
  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/actuator-summary`)
      .then(function (response) {
        setMotorDetails(response.data.content)
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(res => {
        dispatch(getPlotList(res.data.content));
        dispatch(toggleLoader(false));
      });
  }, []);


  useEffect(() => {
    if (selectedPlot.id) {
      dispatch(getSelectedPlot({}));
    }

    if (!isLoading) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(function (response) {
        dispatch(getSelectedFarm(response.data.content));
        setIsLoading(false);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });
  }, [isLoading]);

  useEffect(() => {
    if (!isLoadingSource) {
      return;
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/source`)
      .then(function (response) {
        if (response.data.content.length > 0) {
          dispatch(getFarmSource(response.data.content[0]));
        } else {
          dispatch(getFarmSource({}));
        }
        dispatch(getAllFarmSources(response.data.content));
        setLoadingSource(false);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });
  }, [isLoadingSource]);


  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });
  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });


  const sources = useSelector(state => {
    return state.farmList.farmSourceAll;
  });

  // const client = new Paho.Client('wss://mqtt.senzmate.com:8000/mqtt', 'qwerty12345' + new Date().getTime());
  // client.onConnectionLost = onConnectionLost;
  // client.onMessageArrived = onMessageArrived;
  //
  // useEffect(() => {
  //   if (!client) {
  //     return
  //   }
  //   client.connect({ onSuccess: onConnect, mqttVersionExplicit: true, mqttVersion: 3 });
  // }, []);


  // function onConnect() {
  //   let qos = 0;
  //   if (client.isConnected()) {
  //     client.unsubscribe(`S2F/SA/V1/farm/#`);
  //     client.subscribe(`S2F/SA/V1/farm/` + farmId + `/#`, { qos: qos });
  //   }
  // }
  //
  // function onConnectionLost(responseObject) {
  //   if (responseObject.errorCode !== 0) {
  //     client.connect({ onSuccess: onConnect, mqttVersionExplicit: true, mqttVersion: 3 });
  //   }
  // }
  //
  // function onMessageArrived(message) {
  //   if (message.payloadString) {
  //     let mqttFarmSource = JSON.parse(message.payloadString);
  //     dispatch(getFarmSource(mqttFarmSource));
  //     dispatch(getFarmMqtt(mqttFarmSource));
  //   }
  // }


  return (
    <Container>

      <div className="single-card m-rl-m-8">
        <div className="single-card-items hide-1200 position-relative">
          <div className="card-img-view">
            <Img className="card-img"
                 src={[selectedFarm.imageThumbnail ? selectedFarm.imageThumbnail : selectedFarm.image, farmImg]}
                 alt="farm"/>
            {/*<img src={selectedFarm.imageThumbnail ? selectedFarm.imageThumbnail : selectedFarm.image} className="card-img" alt="farm" />*/}
          </div>
          <div className="card-header-text-top m-r-40">
            <div className="heading">{selectedFarm.name ? toCapsFirst(selectedFarm.name) : "NA"}</div>
            {selectedFarm.meta && <div
              className={'meta-name m-t-5'}>{selectedFarm.meta.D_CANAL + "-" + selectedFarm.meta.FC_CANAL + "-" + selectedFarm.meta.IRRIGATION_NUMBER}</div>}
            <div className="sub-text m-t-5">
              <FeatherIcon className={"map-icon"}
                           icon="map-pin"/> {selectedFarm.location ? selectedFarm.location : " Sri Lanka"}
            </div>
          </div>
          <div className="dis-in-bl">
            <div className="card-content-heading dis-in-bl m-rl-8-15">{t("title.POWER_STATUS")}</div>
            <div
              className="card-content-status w-124 text-center dis-in-bl">{getColouredStatus(isEmpty(farmSource) ? "N/A" : farmSource.type === ACTUATOR_TYPE.TANK ? "N/A" : farmSource.flow ? "ON" : "OFF")}</div>
          </div>
          <div className={"dis-in-bl m-t-5"}>
            {/*<div hidden={sources && sources.length > 1} className="dis-in-bl">*/}
            <div className="card-content-heading dis-in-bl m-rl-8-15">{t("title.WORKING_SENSORS")}</div>

            <div className="card-content-status w-124 text-center dis-in-bl p-0">
              <div className="d-inline-block p-2">
                <div
                  style={{color: (sensorDetails?.noOfOfflineKits === 0 && sensorDetails?.noOfKits === 0) ? 'grey' : (sensorDetails?.noOfOfflineKits === 0 ? '#22c687' : '#FF4343')}}>
                  <span className="font-weight-bold">
                    {sensorDetails?.noOfKits - sensorDetails?.noOfOfflineKits} </span><span
                  className="outOf">out of</span> {sensorDetails?.noOfKits}
                </div>
              </div>
            </div>
            {/*</div>*/}

            {/*<SourceSelector farmId={farmId} />*/}

            <div className="dis-in-bl">
              <div className="card-content-heading dis-in-bl m-rl-8-15">{t("title.WORKING_MOTORS")}</div>
              <div
                className="card-content-status w-124 text-center dis-in-bl">
                <div
                  style={{color: (motorDetails?.noOfOfflineKits === 0 && motorDetails?.noOfKits === 0) ? 'grey' : (motorDetails?.noOfOfflineKits === 0 ? '#22c687' : '#FF4343')}}>
                <span className="font-weight-bold">
                          {motorDetails?.noOfKits - motorDetails?.noOfOfflineKits} </span><span
                  className="outOf">out of</span> {motorDetails?.noOfKits}
                </div>
              </div>
            </div>
          </div>
          <div className={'weather-box-position'}>
            <WeatherPop/>
          </div>

        </div>
        {/*</div>*/}
        {/*<div className={"farm-header-tab-box"}>*/}
        {/*  <div className={"farm-header-tab"}>Smart Farm</div>*/}
        {/*  <div className={"farm-header-tab tab-active"}>Daily Update </div>*/}
        {/*  <div className={"farm-header-tab"}>Setting</div>*/}
        {/*</div>*/}
        {/*<WeatherPop />*/}

        {/*{sources.length > 1 &&*/}
        {/*  <div className={'single-card-items show-992 p-0'}>*/}
        {/*    <div className="farm-heading-mobile pt-2 pb-2">{selectedFarm.name ? toCapsFirst(selectedFarm.name) : "NA"}</div>*/}
        {/*    <div className={"dis-in-bl pl-0 pt-3 pb-3"}>*/}
        {/*      <div hidden={sources && sources.length > 1} className="dis-in-bl">*/}
        {/*        <div className="dis-in-bl m-rl-8-15 m-font-size">{t("title.SOURCE")}</div>*/}

        {/*        <div className="card-content-status text-green w-124 text-center dis-in-bl p-0 m-0 m-font-size">*/}
        {/*          <div className="d-inline-block p-2">*/}
        {/*            {farmSource.type ? farmSource.type : "NA"} </div></div>*/}
        {/*      </div>*/}

        {/*      <SourceSelector farmId={farmId} />*/}
        {/*    </div>*/}
        {/*    <div>*/}

        {/*    </div>*/}
        {/*  </div>*/}
        {/*}*/}

        <FarmTab farmId={farmId}/>
      </div>

    </Container>
  )
};


export default FarmHeader
