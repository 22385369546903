import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getDisplayName} from "../../../shared/utils/utils";

const SensorInput = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [sensorData, setSensorData] = useState([]);

  useEffect(() => {
    if (!props.sensors) return;

    setSensorData(props.sensors);
  }, [props.sensors]);

  const handleChange = (index, event) => {
    const {name, value} = event.target;
    const updatedSensorData = [...sensorData];
    updatedSensorData[index][name] = value;
    setSensorData(updatedSensorData);
  };

  const handleAdd = () => {
    const kitId = props.kit.id;
    dispatch(toggleLoader(true));

    const filteredSensorData = sensorData.map(sensor => {
      const {id, clusterName, coordinate, creationDate, deviceId, modifiedDate, time, ...rest} = sensor;
      return {
        ...rest
      };
    });

    axios.post(process.env.REACT_APP_HOST + `/core/kit/${kitId}/properties`, filteredSensorData)
      .then(response => {
        toast.success("Sensor data added successfully");
        props.onClose();
      })
      .catch(error => {
        console.error('error: ', error)
        toast.error("Failed to add sensor data");
      }).finally(() => {
      dispatch(toggleLoader(false))
    });
  };

  return (
    <div>
      <div className={"sa-popup-bg "}>
        <div className="sa-popup">
          <div className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span className={'sa-model-heading'}>{t("title.ADD_SENSOR_DATA")}</span>
              <div className="sa-popup-close-icon" onClick={() => props.onClose()}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              {sensorData.map((sensor, index) => (
                <div className="col-md-6" key={index}>
                  <div className="form-group">
                    <label>{`${getDisplayName(sensor, props.kit.metaData)} ${sensor.unit ? `(${sensor.unit})` : ''}`}</label>
                    <input
                      onChange={(e) => handleChange(index, e)}
                      value={sensor.value || ''}
                      type="text"
                      className={`form-control`}
                      name="value"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="sa-popup-btn">
              <button
                type={"button"}
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                onClick={() => props.onClose()}
              >
                {t("button.CANCEL")}
              </button>
              <button
                className="sa-popup-secondary-btn-style"
                onClick={handleAdd}
              >
                {t("button.ADD")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorInput;
