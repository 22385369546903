import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Container from "react-bootstrap/Container";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {VIEW} from "../../utils/Enum";
import {changeView} from "../../shared/actions/setting";

export default function CCRHeader() {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  function onPaths(paths) {
    return (match, location) => {
      return location.pathname.match(paths);
    };
  }

  useEffect(() => {
    dispatch(changeView(VIEW.CCR_PROFILE));
  }, []);

  return (
    <Container>
      <div className="single-card m-rl-m-8">
        <div className={`d-flex text-center tab-container m-rl-m-16`}>
          {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY") && */}
          <NavLink to={'/ccr/ccr-supply'} isActive={onPaths("/ccr-supply")}
                   activeClassName="plot-tog-active">
            <div id={'tabSetting'} className="farm-header-toggles">
              <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="package"/><span
              className="farm-tab-yield"> {t("title.SUPPLIES")}</span></div>
          </NavLink>
          {/* } */}
          <NavLink to={'/ccr/ccr-demand'} isActive={onPaths("/ccr-demand")}
                   activeClassName="plot-tog-active">
            <div id={'tabSetting'} className="farm-header-toggles">
              <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="inbox"/><span
              className="farm-tab-yield"> {t("title.DEMANDS")}</span></div>
          </NavLink>
          <NavLink to={'/ccr/deals'} isActive={onPaths("/deals")}
                   activeClassName="plot-tog-active">
            <div id={'tabSetting'} className="farm-header-toggles">
              <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="shopping-cart"/><span
              className="farm-tab-yield"> {t("title.DEALS")}</span></div>
          </NavLink>
        </div>
      </div>
    </Container>
  )
}
