import React, {useEffect, useState} from "react";
import {getIcon, isDeviceOnline} from "../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedSensor} from "../../actions/sensor";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {toggleLoader} from "../../shared/actions/setting";
import {toast} from "react-toastify";
import {TOGGLE} from "../../utils/Enum";
import {isEmpty, toBrowserTimeZone} from "../../shared/utils/utils";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import DeviceBatteryReport from "../smart-farm/plot/sensor-item-battery-report";
import {onValue, ref} from "firebase/database";
import {database} from "../../utils/firebaseConfig";

const Kit = (props) => {
  const [kit, setKit] = useState({});
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [actions, setActions] = useState([]);
  const [properties, setProperties] = useState([]);

  const selectedSensor = useSelector(state => {
    return state.sensor.selectedSensor;
  });

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    if (!props.kitId) {
      setKit({});
      return;
    }

    if (!isEmpty(kit) && props.kitId !== kit.id) {
      setKit({});
    }

    dispatch(toggleLoader(true));

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.kitId)
      .then(res => {
        setKit(res.data.content);
        dispatch(toggleLoader(false));
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
        dispatch(toggleLoader(false));
      }
    );

  }, [props.kitId]);

  useEffect(() => {
    if (!props.kitId) {
      return
    }

    const query = ref(database, 'kitActions/' + props.kitId + '/');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data) {
        setActions(data);
      }
    });
  }, [props]);

  useEffect(() => {
    if (kit.actions !== undefined) {
      const updatedKit = {...kit};
      updatedKit.actions = actions

      const validTimeStrings = actions
        .filter(action => action.timeString)
        .map(action => new Date(action.timeString));

      if (validTimeStrings.length !== 0) {
        const latestDate = new Date(Math.max(...validTimeStrings));

        if (toBrowserTimeZone(kit.lastSeen) < toBrowserTimeZone(latestDate)) {
          updatedKit.lastSeen = latestDate;
        }
      }
      setKit(updatedKit)
    }
  }, [actions]);

  useEffect(() => {
    if (!props.kitId) {
      return
    }

    const query = ref(database, 'kits/' + props.kitId + '/');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data) {
        setProperties(data);
      }
    });
  }, [props]);

  useEffect(() => {
    if (kit.properties !== undefined) {
      const updatedKit = {...kit};
      updatedKit.properties = properties

      const validTimeStrings = properties
        .filter(property => property.timeString)
        .map(property => new Date(property.timeString));

      if (validTimeStrings.length !== 0) {
        const latestDate = new Date(Math.max(...validTimeStrings));

        if (toBrowserTimeZone(kit.lastSeen) < toBrowserTimeZone(latestDate)) {
          updatedKit.lastSeen = latestDate;
        }
      }
      setKit(updatedKit)
    }
  }, [properties]);

  function showGraphReport() {
    setSelectedDevice(true);
  }

  function isOnline(time) {
    if (isDeviceOnline(time, kit.interval)) {
      return (<div className="heading">
        <div className="status-circle-green"/>
        Online
      </div>);
    } else {
      return (<div className="heading">
        <div className="status-circle-black"/>
        Offline
      </div>);
    }

  }

  function sensorClick(index) {
    if (toggle === TOGGLE.MAP) {
      dispatch(getSelectedSensor(index));
    }
  }

  return (
    <div className={window.innerWidth > 620 ? "col-xl-4 col-lg-6" : "mo-plot-list-card"}
         onClick={() => sensorClick(props.index)} style={{margin: 0}}>
      <div className="single-card sensor-kit-m">
        <div className={"single-card-header " + (props.index === selectedSensor ? 'card-top-border' : '')}>
          <div className="row sensor-kit-main sa-table-flex">
            <div className="card-header-text-2">
              <div className="heading limit-chara-actuator-sensor" title={props.kitId}>{props.kitId}</div>
            </div>
            <div className="text-center sensor-kit">
              <div className="card-header-status">
                {isOnline(toBrowserTimeZone(kit.lastSeen))}
                <div className="sub-text">{toBrowserTimeZone(kit.lastSeen)}</div>
              </div>
            </div>
            <div className={"m-r-6"}>
              {kit.battery ?
                <div onClick={showGraphReport} className="card-header-actions sa-cursor pull-right">
                  <FeatherIcon className={"text-yellow"} icon="battery"/> {kit.battery.reading}%
                </div>
                :
                <div className="card-header-actions pull-right">
                  NA
                </div>
              }
            </div>
          </div>
        </div>
        <div className="single-card-content">
          <div className="row p-1">
            {kit.properties && kit.properties.map((item, index) => (
              <div className="col-6" key={'sensor' + index}>
                <div className="sensor-details">
                  {getIcon(item.code)}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorName-sa">{item.displayValue}</div>
                    <div className="sensor-Name limit-sensorName-sa">{item.displayName}</div>
                  </div>
                </div>
              </div>
            ))}
            {kit.actions && kit.actions.map((item, index) => (
              props.actuatorIds.includes(item.number) &&
              <div className="col-6" key={'sensor' + index}>
                <div className="sensor-details">
                  {getIcon(item.code, true)}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorName-sa">{item.displayValue}</div>
                    <div className="sensor-Name limit-sensorName-sa">{item.displayName}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedDevice &&
        <DeviceBatteryReport device={{kit: kit}} onClose={() => setSelectedDevice(null)}/>
      }
    </div>
  )
};

export default Kit
