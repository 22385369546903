import React, {useEffect, useState} from 'react'
import {Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import * as _ from "underscore";
import ReactPaginate from 'react-paginate';
import FilterHandler from '../shared/utils/FilterHandler';

const NotificationPage = () => {
  const dispatch = useDispatch();
  const [notificationsUpdate, setNotificationsUpdate] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadNotifications, setLoadNotifications] = useState([]);
  const [notificationListAll, setNotificationListAll] = useState([]);
  const [farmName, setFarmName] = useState([])
  const [type, setType] = useState([])
  const {handleFilter, filteredList, filters} = FilterHandler(
    setFilteredList,
    notificationListAll
  );
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const sortedNotifications = [...loadNotifications].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const pageCount = Math.ceil(sortedNotifications.length / itemsPerPage);
  const displayedNotifications = sortedNotifications.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  function setFilteredList() {
    setLoadNotifications(filteredList);
  }

  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  const selectedNotification = useSelector((state) => {
    return state.setting.selectedNotification;
  });

  // useEffect(() => {
  //   dispatch(toggleLoader(true));
  //   dispatch(changeView(VIEW.NOTIFICATION_ALL))
  //   axios
  //     .get(
  //       process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + selectedCorporate + `/notifications?pageSize=100&pageIndex=0`
  //     )
  //     .then((res) => {
  //       setNotifications(res.data.content.content);

  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     });
  // }, [selectedCorporate]);

  useEffect(() => {
    if (!selectedNotification) return;

    setNotifications(selectedNotification);
  }, [selectedNotification]);

  useEffect(() => {
    setLoadNotifications(
      _.union(changeKey(notifications), notificationsUpdate)
    );
    setNotificationListAll(
      _.union(changeKey(notifications), notificationsUpdate)
    );
  }, [notificationsUpdate, notifications]);

  useEffect(() => {
    if (notificationListAll.length === 0) {
      return
    }
    setFarmName(_.uniq(_.pluck(notificationListAll, "farmName")))
    setType(_.uniq(_.pluck(notificationListAll, "type")))
  }, [notificationListAll])


  function changeKey(arr) {
    return arr.map((s) => {
      if (s.hasOwnProperty("startTime")) {
        s.createdAt = s.startTime;
        s.seenStatus = false;
        delete s.startTime;
      }
      return s;
    });
  }

  return (
    <div className={"list-view full-list-view"}>
      <Container className={"m-t-8"}>
        <div className={"single-card m-rl-m-8 p-a-16"}>
          <div className={"sa-table-flex"} style={{marginBottom: 8}}>
        <span>
          <select
            className="sa-filter m-l-0"
            onChange={handleFilter}
            name={"type"}
          >
            <option value={""}>
              {filters && filters.type ? "ALL" : "Type"}
            </option>
            {type.map((item, index) => (
              <option key={index} value={item}>
                {item ? item.replaceAll('_', " ") : "NA"}
              </option>
            ))}
          </select>
          <select
            className="sa-filter sa-table-m-r-0 sa-mobile-view-m"
            onChange={handleFilter}
            name={"farmName"}
          >
            <option value={""}>
              {filters && filters.farmName ? "ALL" : "Farm Name"}
            </option>
            {farmName.map((item, index) => (
              <option key={index} value={item}>
                {item == "" ? "NONE" : item}
              </option>
            ))}
          </select>
        </span>
          </div>
          <div className="sa-table-container notification-calc-height">
            <table className="table table-borderless">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>Notification</th>
                <th className={"sa-table-head-sticky"}>Type</th>
                <th className={"sa-table-head-sticky"}>Farm Name</th>
                <th className={"sa-table-head-sticky"}>Plot Name</th>
                <th className={"sa-table-head-sticky"}>Date</th>
              </tr>
              </thead>
              <tbody>
                {displayedNotifications.map((item, index) => (
                  <tr key={index}>
                    <td className={'sa-table-data'}>{item.message}</td>
                    <td className={'sa-table-data'}>{item.type ? item.type.replace("_", " ") : "NA"}</td>
                    <td className={'sa-table-data'}>{item.farmName ? item.farmName : "NA"}</td>
                    <td className={'sa-table-data'}>{item.plotName ? item.plotName : "NA"}</td>
                    {item.type === 'DEVICE_OFFLINE' ? (
                      <td className={'sa-table-data'}>
                        {item.createdAt.slice(0, 10)} - <br/>
                        {item.endTime ? item.endTime.slice(0, 10) : "NA"}
                      </td>
                    ) : (
                      <td className={'sa-table-data'}>{item.createdAt.slice(0, 10)}</td>
                    )}

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination Component */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousClassName={"prev"}
            nextClassName={"next"}
            disabledClassName={"disabled"}
          />
        </div>
      </Container>
    </div>
  )
}

export default NotificationPage
