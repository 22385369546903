import React, {useEffect, useState} from "react";
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps"
import {compose, withProps} from "recompose";
import {DrawingManager} from "react-google-maps/lib/components/drawing/DrawingManager";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import FormHandler from "../../../shared/utils/FormHandler";
import {FarmAdd} from "../../../utils/FormValidationRules";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {toggleLoader, toggleLoaderClear} from "../../../shared/actions/setting";
import imageCompression from "browser-image-compression";
import {StandaloneSearchBox} from "@react-google-maps/api";
import {getFarmList} from "../../../actions/farm";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {useTranslation} from "react-i18next";
import {getSelectedFarmModule, getSelectedUserModule} from "../../../actions/module";
import {isKeells, uploadImage} from "../../../utils/Utils";
import {farmType} from "../../../utils/WhiteLabeling";

function getPolygonCoords(e) {
  let len = e.getPath().getLength();
  let coordinates = []
  let err = null;
  if (len >= 3) {
    for (let i = 0; i < len; i++) {
      let latlan = {}
      latlan.lat = e.getPath().getArray()[i].lat();
      latlan.lng = e.getPath().getArray()[i].lng();
      coordinates.push(latlan)
    }
  } else {
    err = 'A Farm should consist at least 3 points'
  }
  return {coordinates: coordinates, err: err}
}

const MyMapComponent = compose(
  withProps({

    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDobm_3OLsu0cFTLt-zUAgt-_7Oi-TkGT4&v=3.exp&libraries=geometry,drawing,places,visualization",
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `332px`}}/>,
    mapElement: <div style={{height: `100%`}}/>
  }),
  withScriptjs,
  withGoogleMap
)(props => {
    const [map, setMap] = useState(null);
    const [searchBox, setSearchBox] = useState(null);

    const onPlacesChanged = () => {
      const places = searchBox.getPlaces();
      // console.log(place.geometry.location)

      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      //
      // coordinates.map((hotel, i) => {
      //     const position = new google.maps.LatLng(hotel.lat, hotel.lng);
      //     bounds.extend(position);
      // });
      //
      places.forEach((place) => {
        // console.log(place.geometry.location)
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      })
      map.fitBounds(bounds);

    };
    const onSBLoad = ref => {
      setSearchBox(ref);
    };
    const {t} = useTranslation();
    return (

      <div className={'position-relative'}>
        <GoogleMap defaultZoom={8}
                   ref={mapRef => {
                     setMap(mapRef);
                   }}

                   defaultCenter={{lat: 6.927079, lng: 79.861244}}
          // defaultCenter={new google.maps.LatLng(,)}
                   mapTypeId={"satellite"}

        >
          <DrawingManager
            defaultDrawingMode={'polygon'}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                // position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon'],
              }
            }}
            onPolygonComplete={(e) => {


              props.coordinate(getPolygonCoords(e))
            }}
          />
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <input
              type="text"
              style={{
                boxSizing: 'border-box',
                border: `1px solid transparent`,
                width: `270px`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.1)`,
                fontSize: `14px`,
                outline: `none`,
                margin: 'center',
                textOverflow: `ellipses`,
                position: 'absolute',
                bottom: '285px',
                marginLeft: '40%'
              }}
              placeholder={t("placeHolder.SEARCH_PLACE")}
            />
          </StandaloneSearchBox>
        </GoogleMap>
      </div>
    )
  }
);
// export default MyMapComponent
export default function FarmAddMap(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [isMapAvailable, setIsMapAvailable] = useState(true);
  const [farmTypes, setFarmTypes] = useState(farmType());
  const [fileName, setFileName] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [thumbnail, setThumbnail] = useState(false);
  const [errMap, setErrMap] = useState(null);
  const [corporateData, setCorporateData] = useState([]);
  const dispatch = useDispatch();
  const [createFarm, setCreateFarm] = useState(null);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    handleKeyDown,
    initForm,
    setValue,
    deleteErrors,
  } = FormHandler(farmAdd, FarmAdd);

  function farmAdd() {
    setIsLoading(true)
  }


  function coordinates(e) {
    values.coordinates = e.coordinates
    setErrMap(e.err)
  }

  async function uploadFarmImage(e) {
    e.persist()
    const imageFile = e.target.files[0];
    const thumbnail = "thumbnail_" + new Date().getTime() + '.jpeg';
    const imageName = "image_" + new Date().getTime() + '.jpeg';
    setFileName(imageName)

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 96,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(async function (compressedFile) {
        let response = await uploadImage(thumbnail, compressedFile);
        values.imageThumbnail = response;
        setThumbnail(true)
      })
      .catch(function (error) {

      });
    let response = await uploadImage(imageName, imageFile);
    values.image = response;
    setIsUpload(true)
  }

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    setFormErr("");
    setIsUpload(false);
    setThumbnail(false)
    setIsLoading(false)
  }

  const farmList = useSelector(state => {
    return state.farmList.farmList
  });

  function updateModule() {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/moduleConfig`)
      .then((res) => {
        dispatch(getSelectedUserModule(res.data.content.enabledModules));
        dispatch(getSelectedFarmModule(res.data.content.farmModules));
      })
      .finally(() => {
        dispatch(toggleLoaderClear(true))
      });

  }

  useEffect(() => {
    if (!isLoading) {
      return
    }
    // values.corporateId = "5f0fd718b03da31a23d54ad9"
    if (fileName) {
      if (!values.imageThumbnail || !values.image) {
        dispatch(toggleLoader(true))
      } else {
        dispatch(toggleLoader(true))
        axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm`, values)
          .then(res => {
            toast.success(t("successMsg.FARM_CREATED_SUCCESSFULLY"));
            resetForm()
            setFileName(null)
            setIsUpload(false)
            dispatch(toggleLoader(false))
            let data = farmList
            data.push(res.data.content)
            dispatch(getFarmList(data));
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              setFormErr(error.response.data.message);
            }

          }
        ).finally(() => {
          props.onClose();
          // dispatch(toggleLoaderClear(true))
          console.log("final")
          updateModule()
        })
        // dispatch(toggleLoader(false))

      }
    } else {

      dispatch(toggleLoader(true))
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm`, values)
        .then(res => {
          toast.success(t("successMsg.FARM_CREATED_SUCCESSFULLY"));
          resetForm()
          setFileName(null)
          setIsUpload(false)
          setCreateFarm(res.data.content.id)
          dispatch(toggleLoader(false))
          let data = farmList
          data.push(res.data.content)
          dispatch(getFarmList(data));
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }

        }
      ).finally(() => {
        props.onClose();
        updateModule()
      })

    }

  }, [isLoading, thumbnail, isUpload])

  useEffect(() => {
    if (!createFarm || !props.selectedCorporate || props.data) {
      return
    }
    dispatch(toggleLoader(true))

    const data = {
      birthDate: props.data.birthDate,
      deceased: props.data.deceased,
      firstName: props.data.firstName,
      gender: props.data.gender,
      homeAddress: props.data.homeAddress,
      houseHoldHead: props.data.houseHoldHead,
      nicNumber: props.data.nicNumber,
      phoneNumber: props.data.phoneNumber,
      id: props.data.id,
      farms: [...props.data.farms, createFarm]
    };

    axios.put(process.env.REACT_APP_HOST + "/user/" + getUserId() + "/corporate/" + props.selectedCorporate + "/participant/" + props.data.id, data)
      .then((res) => {
        toast.success(t("successMsg.FARMER_UPDATED_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        props.getAllParticipant();
      });
  }, [props.selectedCorporate, createFarm]);

  const {t} = useTranslation();

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate`)
      .then(res => {
        setCorporateData(res.data.content)
      })
      .catch(function (error) {
      });
  }, [])

  function handleChangeCorporate(event) {
    if (isKeells(event.target.value)) {
      setFarmTypes(farmType("KEELLS"))
    } else {
      setFarmTypes(farmType())
    }
    setValue({farmType: ""})
    handleChange(event)
  }

  return (
    <div className={'px-3'}>
      <div hidden={!isMapAvailable}>
        <MyMapComponent coordinate={coordinates}/>
      </div>


      {!isMapAvailable && <form onSubmit={handleSubmit}>
        <div className="row sa-modal-content">
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.FARM_NAME")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.name || ''} type="text"
                     className={`form-control ${errors.name && "warning-input"}`}
                     name="name"
                     placeholder={t("placeHolder.ENTER_FARM_NAME")}/>
              {errors.name && (<p className="warning-input-msg ">{errors.name}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.CORPORATE_NAME")}</label>
              <select onChange={handleChangeCorporate}
                      className={`form-control  ${errors.corporateId && "warning-input"}`}
                      name="corporateId">
                <option value={""} hidden={true}>{t("title.SELECT_CORPORATE")}</option>
                {corporateData.map(item => (<option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </select>
              {errors.corporateId && (<p className="warning-input-msg ">{errors.corporateId}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="assignee">{t("title.FARM_TYPE")}</label>
              <select onBlur={handleOnBlur} onChange={handleChange}
                      value={values.farmType || ''}
                      className={`form-control ${errors.farmType && "warning-input"}`}
                      name="farmType">
                <option value={""} hidden>{t("placeHolder.SELECT_FARM_TYPE")}</option>
                {farmTypes.map((data, i) => (<option key={i + "id"} value={data.value}>{data.name}</option>))}


              </select>
              {errors.farmType && (
                <p className="warning-input-msg">{errors.farmType}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.LOCATION")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.location || ''} type="text"
                     className={`form-control ${errors.location && "warning-input"}`}
                     name="location"
                     placeholder={t("placeHolder.ENTER_LOCATION")}/>
              {errors.location && (<p className="warning-input-msg ">{errors.location}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.ESTIMATED_AREA")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.area || ''} type="number"
                     className={`form-control ${errors.area && "warning-input"}`}
                     name="area"
                     onKeyDown={handleKeyDown}
                     placeholder={t("placeHolder.ENTER_ESTIMATED_AREA")}/>
              {errors.area && (<p className="warning-input-msg ">{errors.area}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.CONTACT_NAME")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.contactName || ''} type="text"
                     className={`form-control ${errors.contactName && "warning-input"}`}
                     name="contactName"
                     placeholder={t("placeHolder.ENTER_CONTACT_NAME")}/>
              {errors.contactName && (<p className="warning-input-msg ">{errors.contactName}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.CONTACT_NUMBER")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.contactPhoneNumber || ''} type="number"
                     className={`form-control ${errors.contactPhoneNumber && "warning-input"}`}
                     name="contactPhoneNumber"
                     placeholder={t("placeHolder.ENTER_CONTACT_NUMBER")} rows="3"
              />
              {errors.contactPhoneNumber && (
                <p className="warning-input-msg ">{errors.contactPhoneNumber}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.CONTACT_EMAIL")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.contactEmail || ''} type="email"
                     className={`form-control ${errors.contactEmail && "warning-input"}`}
                     name="contactEmail"
                     placeholder={t("placeHolder.ENTER_CONTACT_EMAIL")} rows="3"
              />
              {errors.contactEmail && (<p className="warning-input-msg ">{errors.contactEmail}</p>)}
            </div>
          </div>
          {/*<div className="col-md-6">*/}
          {/*    <div className="form-group m-b-16">*/}
          {/*        <label htmlFor="email">Corporate Name</label>*/}
          {/*        <select onBlur={handleOnBlur} onChange={handleChange}*/}
          {/*                value={values.corporateId || ''}*/}
          {/*                className={`form-control ${errors.farmType && "warning-input"}`}*/}
          {/*                name="corporateId">*/}
          {/*            <option value={""} hidden>Select Corporate Name</option>*/}
          {/*            <option>SenzMate Dev</option>*/}
          {/*            <option>ToursAZ</option>*/}
          {/*            <option>Xindicate Dev</option>*/}


          {/*        </select>*/}
          {/*        {errors.corporateId && (<p className="warning-input-msg ">{errors.corporateId}</p>)}*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="col-md-12">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.FARM_IMAGE")}</label>
              <div className="custom-file">
                <input type="file" className="form-group m-b-16" id="customFile" onChange={uploadFarmImage}
                       accept="image/*"/>
                <label className="custom-file-label farm-image-upload d-flex align-items-center sa-cursor"
                       htmlFor="customFile">{fileName ? fileName : t("title.FARM_IMAGE")}</label>
              </div>
              {/*{errors.contactEmail && (<p className="warning-input-msg ">{errors.contactEmail}</p>)}*/}
            </div>
          </div>

        </div>

        {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

        <div className="sa-modal-footer">
          <div className="sa-modal-footer-btn">
            <button type={"button"} className="btn btn-sa-text w-120p" onClick={() => props.onClose()}>
              {t("button.CANCEL")}
            </button>
            <button className="btn btn-sa-primary w-120p" type="submit">
              {t("button.DONE")}
            </button>
          </div>
        </div>
      </form>}

      {isMapAvailable && <div className="sa-modal-footer pt-2">
        {errMap && <div className={'login-warning-msg mt-3'}>{errMap}</div>}
        <div className="sa-modal-footer-btn">
          <button type={"button"} className="btn btn-sa-text w-120p" onClick={() => props.onClose()}>
            {t("button.CANCEL")}
          </button>
          <button className="btn btn-sa-primary w-120p"
                  onClick={() => {
                    if (errMap || !values.coordinates) {
                      if (!values.coordinates) {
                        setErrMap("Please draw Farm")
                      }
                      return
                    }
                    setIsMapAvailable(false)
                  }}>
            {t("button.NEXT")}
          </button>
        </div>
      </div>}


    </div>
  )
}
