import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFarmMqtt, getFarmSource, getSelectedFarm} from "../../../actions/farm";
import {getColouredStatus, isMobileView, toCapsFirst} from "../../../utils/Utils";
import FeatherIcon from 'feather-icons-react';
import {ACTUATOR_TYPE, TOGGLE} from "../../../utils/Enum";
import axios from "axios";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {Img} from "react-image";
import FarmImage from "../../../images/farm.jpg";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const FarmItem = (props) => {
  const {t, i18n} = useTranslation();
  let history = useHistory();
  const [farmSource, setFarmSource] = useState({});
  const [isWeather, setIsWeather] = useState(false);
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [weatherActual, setWeatherActual] = useState([]);
  const [weatherIcon, setWeatherIcon] = useState();
  const [sensorDetails, setSensorDetails] = useState({});
  const [motorDetails, setMotorDetails] = useState({});
  const dispatch = useDispatch();
  // const src = require(`./avatars/\${avatar}`);

  const farmModules = useSelector(state => {
    return state.modules.farmModules;
  });

  // useEffect(() => {
  //   const source = axios.CancelToken.source();

  //   dispatch(toggleLoader(true));

  //   const requests = [
  //     // axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farm.id + `/source`, {
  //     //   cancelToken: source.token
  //     // }),
  //     axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farm.id + `/summary`, {
  //       cancelToken: source.token
  //     }),
  //     axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farm.id + `/actuator-summary`, {
  //       cancelToken: source.token
  //     })
  //   ];

  //   Promise.all(requests)
  //     .then(function (responses) {
  //       const [summaryResponse, actuatorSummaryResponse] = responses;
  //       // if (sourceResponse.data.content.length > 0) {
  //       //   setFarmSource(sourceResponse.data.content[0]);
  //       //   dispatch(getFarmSource(sourceResponse.data.content[0]));
  //       // } else {
  //       //   dispatch(getFarmSource({}));
  //       // }
  //       setSensorDetails(summaryResponse.data.content);
  //       setMotorDetails(actuatorSummaryResponse.data.content);
  //     })
  //     .catch(function (error) {
  //       if (axios.isCancel(error)) {
  //         // Request was canceled, no need to handle errors in this case
  //       } else {
  //         // Handle other errors here
  //       }
  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     });

  //   return () => {
  //     source.cancel();
  //   };
  // }, []);

  useEffect(() => {
    if (!props.sensor) {
      setSensorDetails({});
      return;
    }
    setSensorDetails(props.sensor);
  }, [props.sensor]);

  useEffect(() => {
    if (!props.motor) {
      setMotorDetails({});
      return;
    }
    setMotorDetails(props.motor);
  }, [props.motor]);

  useEffect(() => {
    if (!props.source) {
      dispatch(getFarmSource({}));
      return;
    }

    setFarmSource(props.source);
    dispatch(getFarmSource(props.source));

  }, [props.source]);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const farmSourceMqtt = useSelector(state => {
    return state.farmList.farmMqttStatus;
  });

  const goToDashboard = () => {
    if (toggle !== TOGGLE.MAP || selectedFarm.id === props.farm.id) {
      history.push(props.farm.id + "/plot");
    }
    dispatch(getSelectedFarm(props.farm));
  };


  function goToScouting(e) {
    e.stopPropagation();
    history.push(props.farm.id + "/scouting");
  }

  function goToScheduling(e) {
    e.stopPropagation();
    history.push(props.farm.id + "/irrigation-setting");
  }

  function goToReport(e) {
    e.stopPropagation();
    history.push(props.farm.id + "/report");
  }

  useEffect(() => {
    if (!farmSourceMqtt) {
      return
    }
    if (farmSourceMqtt.farmId === props.farm.id) {
      farmSource.irrigationMode = farmSourceMqtt.irrigationMode;
      farmSource.flow = farmSourceMqtt.flow;
      dispatch(getFarmMqtt(null));
    }
  }, [farmSourceMqtt]);


  useEffect(() => {
    if (!props.farm.location || !isMobileView()) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/weather/user/` + getUserId() + `/weather/city/name/` + props.farm.location)
      .then(res => {
        setWeatherInfo(res.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => {
      // setWeatherIcon(_.pluck(_.pluck(weatherInfo,'current'), 'weather')[0])
    })
  }, [props.farm])

  useEffect(() => {
    if (!weatherInfo || weatherInfo.length <= 0) {
      return
    }
    if (_.first(weatherInfo).readingType === "FORECAST") {
      setWeatherActual([_.first(weatherInfo)])
    } else {
      setWeatherActual(_.where(weatherInfo, {readingType: "ACTUAL"}))
    }

    setWeatherIcon(_.pluck(_.pluck(_.pluck(weatherInfo, 'current'), 'weather')[0], 'icon')[0])

  }, [weatherInfo])

  return (
    <div className="single-card ma-res-bot sa-cursor" id={'farm'} onClick={(e) => {
      e.persist();
      if (e.target.parentNode.id === 'farm' || e.target.parentNode.id === 'farmText' || e.target.parentNode.id === 'farmImg' || e.target.parentNode.id === 'card-header') {
        goToDashboard()
      }

    }}>
      <div className={"single-card-header " + (props.farm.id === selectedFarm.id ? 'card-top-border' : '')}
           id={'card-header'}>
        <div className="card-img-view" id={'farmImg'}>
          {/*{props.farm && <Img className="card-img" src={props.farm.image ? props.farm.image : FarmImage} alt="farm"/>}*/}
          <Img className="card-img"
               src={[props.farm.imageThumbnail ? props.farm.imageThumbnail : props.farm.image, FarmImage]} alt="farm"/>
          {/*<img src={props.farm.imageThumbnail ? props.farm.imageThumbnail : props.farm.image} className="card-img" alt="farm" />*/}
        </div>

        {props.farm.weatherEnabled && weatherIcon && <div className={"weather-mobile-click"} onClick={(e) => {
          e.stopPropagation();
          setIsWeather(true)
        }} id={'weather'}>
          <img src={`/images/clouds/${weatherIcon}.png`} width={'32px'} className={'mt-1'}
               style={{mixBlendMode: 'multiply'}}/>
        </div>}
        <div className="card-header-text limit-charater-farmname" id={'farmText'} data-toggle="tooltip">
          <label title={toCapsFirst(props.farm.name)} className="heading">{toCapsFirst(props.farm.name)}</label>
          {props.farm.meta && <div
            className={'meta-name mb-2'}>{props.farm.meta.D_CANAL + "-" + props.farm.meta.FC_CANAL + "-" + props.farm.meta.IRRIGATION_NUMBER}</div>}
          <div className="sub-text">
            <FeatherIcon className={"map-icon"}
                         icon="map-pin"/> {props.farm.location ? props.farm.location : "Sri Lanka"}
          </div>
        </div>
      </div>
      <div className="single-card-content">
        <div className="row">
          <div className="col-4 text-center padding-f">
            <div className="card-content-heading">{t("title.WORKING_SENSORS")}</div>
            <div className="card-content-status text-red">
              <div
                style={{color: (sensorDetails?.noOfOfflineKits === 0 && sensorDetails?.noOfKits === 0) ? 'grey' : (sensorDetails?.noOfOfflineKits === 0 ? '#22c687' : '#FF4343')}}>
              <span className="font-weight-bold">
              {sensorDetails?.noOfKits - sensorDetails?.noOfOfflineKits} </span><span
                className="outOf">out of</span> {sensorDetails?.noOfKits}
              </div>
            </div>
          </div>
          <div className="col-4 text-center padding-t">
            <div className="card-content-heading">{t("title.POWER_STATUS")}</div>
            <div
              className="card-content-status">
              {getColouredStatus(farmSource.type === ACTUATOR_TYPE.TANK ? "N/A" : farmSource.flow ? "ON" : "OFF")}</div>
          </div>
          <div className="col-4 text-center padding-s">
            <div className="card-content-heading">{t("title.WORKING_MOTORS")}</div>
            <div
              className="card-content-status">
              <div
                style={{color: (motorDetails?.noOfOfflineKits === 0 && motorDetails?.noOfKits === 0) ? 'grey' : (motorDetails?.noOfOfflineKits === 0 ? '#22c687' : '#FF4343')}}>
               <span className="font-weight-bold">
                 {motorDetails?.noOfKits - motorDetails?.noOfOfflineKits} </span><span
                className="outOf">out of</span> {motorDetails?.noOfKits}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="single-card-footer hide-620">*/}
      {/*  <div  className="row">*/}
      {/*    <div hidden={!farmModules[props.farm.id] || !farmModules[props.farm.id][MODULE.DAILY_UPDATE]} className="col-4 text-center padding-f" onClick={goToScouting}>*/}
      {/*      <a href="#">*/}
      {/*        <div className="card-footer-icon"><FeatherIcon className={"h-12"} icon="eye"/></div>*/}
      {/*        <div className="card-footer-btn">SCOUTING</div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="col-4 text-center padding-s" onClick={goToScheduling}>*/}
      {/*      <a href="#">*/}
      {/*        <div className="card-footer-icon"><FeatherIcon className={"h-12"} icon="clock"/></div>*/}
      {/*        <div className="card-footer-btn">SCHEDULING</div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="col-4 text-center padding-t" onClick={goToReport}>*/}
      {/*      <a href="#">*/}
      {/*        <div className="card-footer-icon"><FeatherIcon className={"h-12"} icon="clipboard"/></div>*/}
      {/*        <div className="card-footer-btn">REPORT</div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*{isWeather &&*/}
      {/*<WeatherPop isWeather={isWeather} weatherActual={weatherActual} weatherInfo={weatherInfo}*/}
      {/*            onClose={() => setIsWeather(false)}/>*/}

      {/*}*/}
    </div>
  )
}

export default FarmItem
