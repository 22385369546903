import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import logo from '../../../src/images/logo.png';
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {toggleLoader} from "../../shared/actions/setting";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";

const FpoMail = () => {
  const {orderId, requestStatus} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  useEffect(() => {

    dispatch(toggleLoader(true));
    axios.put(`${process.env.REACT_APP_HOST}/user/${getUserId()}/order/${orderId}?requestStatus=${requestStatus}`)
      .then(response => {
        toast.success("Status updated successfully");
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [orderId, requestStatus]);

  return (
    <div className="login-background">
      <div className="login-inner">
        <div className="login-box">
          <div className="login-logo">
            <img className="login-logo-image" src={logo} alt="logo"/>
          </div>

          <div className="fpo-mail">
            {requestStatus === 'FPO_APPROVED' && <p>{t("info.FPO_MAIL")}: {orderId} {t("info.MAIL_APPROVED")}</p>}
            {requestStatus === 'FPO_NOT_APPROVED' &&
              <p>{t("info.FPO_MAIL")}: {orderId} {t("info.MAIL_NOT_APPROVED")}</p>}
            <div style={{color: '#28a745'}}>
              <FeatherIcon icon={"check-circle"} className={"check-circle"}/>
            </div>
          </div>

        </div>
      </div>
    </div>);
};


export default FpoMail
