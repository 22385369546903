import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {DateRangePicker} from "rsuite";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {hasPermission,} from "../../utils/Authentication";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {toCapsFirst} from "../../utils/Utils";
import FormHandler from "../../shared/utils/FormHandler";
import {validateTasks} from "../../utils/FormValidationRules";
import {
  changeView,
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader
} from "../../shared/actions/setting";
import FarmTaskView from "../smart-farm/farm/farm-task-view";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import FilterHandler from "../../shared/utils/FilterHandler";
import {ExportToCsv} from "export-to-csv";
import * as _ from "underscore";
import AddTask from "./add-task";

import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";
import {VIEW} from "../../utils/Enum";
import ReactPaginate from "react-paginate";


const AllTasks = (props) => {

  const dispatch = useDispatch();
  // const { farmId } = useParams();
  const [farmId, setFarmId] = useState(null);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [updateIndex, setUpdateIndex] = useState([]);
  const [taskIndex, setTaskIndex] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [deleteObject, setDeleteObject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const [suggestionList, setSuggestionList] = useState([]);
  const [category, setCategory] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [approve, setApprove] = useState(false);
  const [tasksListAll, setTasksListAll] = useState([]);
  const [isAddTask, setIsAddTask] = useState(false);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const sortedTasks = tasks?.sort((a, b) => new Date(b.time) - new Date(a.time)) || [];
  const pageCount = Math.ceil(sortedTasks.length / itemsPerPage);
  const paginatedData = sortedTasks.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  const {
    handleFilter,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, tasksListAll);

  function setFilteredList() {
    setTasks(filteredList)
  }


  function filterActiveUsers(data) {
    const activeUser = [];
    data.map((user) => {
      if (user.status === "ACTIVE") {
        activeUser.push(user);
      }
    });

    setActiveUsers(activeUser)
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    deleteErrors,
  } = FormHandler(AllTasks, validateTasks);


  function exportData() {
    const data = [];
    tasksListAll.forEach(tasks => {
      data.push({
        "Note": tasks.note,
        "Deadline": tasks.endDate ? tasks.endDate : "NA",
        "Category": tasks.category,
        "Assignee": tasks.assignee ? tasks.assignee.lastName : '',
        "Status": tasks.status
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'FarmTask',
      useBom: true,
      noDownload: false,
      headers: ["Note", "Deadline", "Category", "Assignee", "Status"],
      filename: "FarmTask",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }


  function AllTasks() {
    setIsLoading(true);

  }

  function formClick(event) {
    let names = ['suggestion', 'name'];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  useEffect(() => {
    dispatch(changeView(VIEW.TASKS_ALL))
  }, [])
  useEffect(() => {
    if (!farmId) {
      return
    }

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/reviewer-permission')
      .then(res => {
        setCategory(res.data.content)
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    }).finally(() => {
      setReviewList(_.find(category, {category: "TASKS"}))
      dispatch(toggleLoader(false));
    });
  }, [farmId])


  function onEdit(index, tasks) {
    setIsUpdateAvailable(true);
    setFarmId(tasks.farmId);
    setIsModalVisible(true);
    tasks.assigneeId = tasks.assignee.id;
    initForm(tasks)
    setUpdateIndex(index)
    setTaskId(tasks.id);
    tasks.endDate ? setEndDate(new Date(tasks.endDate)) : setEndDate("");
    setTaskIndex(index);
    setFormErr("");
  }

  useEffect(() => {
    if (!farmId) {
      return
    }
    console.log(farmId)
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user')
      .then(res => {
        let data = res.data.content;
        filterActiveUsers(data);
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [farmId]);


  useEffect(() => {
    if (!dateRange) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/task-report')
      .then(res => {
        const taskData = res.data.content;
        setTasks(res.data.content);
        setTasksListAll(res.data.content);
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    })


  }, [dateRange, farmId, isUpdate])

  function handleDeleteTask(id, farmIds) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOR_SURE_YOU_WANT_DELETE_THIS_TASK")
      ,
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_TASK")
    }));
    setDeleteObject({id: id, farmId: farmIds});
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + deleteObject.farmId + '/task-report/' + deleteObject.id)
      .then(res => {
        setTasks(tasks.filter(item => item.id !== deleteObject.id));
        toast.success(t("successMsg.TASK_DELETED_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
      })
  }, [confirmationDialog]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  function differenceDays(endDate) {
    var date1 = new Date(endDate);
    var date2 = new Date();
    var DifferenceTime = date2.getTime() - date1.getTime();
    var DifferenceDays = Math.floor(DifferenceTime / (1000 * 3600 * 24));
    return DifferenceDays
  }

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    setIsUpdateAvailable(false);
    setFormErr("");
  }

  useEffect(() => {
    if (!reviewList) {
      return
    }
    setApprove(_.contains(_.pluck(reviewList.reviewers, 'id'), getUserId()))

  }, [reviewList])

  const {t, i18n} = useTranslation();
  console.log(activeUsers)


  return (
    <div className={"list-view full-list-view"}>
      <div className={"half-list-container"}>
        <div className={"scouting-container"}>
          <Container>
            {
              <div className={"single-card m-rl-m-8 p-a-16"}>

                <div className={'sa-table-flex sa-table-position'}>
                                <span>
                                    <span className={"sa-table-float-left"}><DateRangePicker
                                      placeholder={t("title.LAST_WEEK")}
                                      disabledDate={date => date > new Date()} disabled={false}
                                      onChange={(e) => onCheck(e)}
                                      oneTap={false} onOk={(e) => onCheck(e)}
                                    />
                                    </span>
                                    <span className={'sa-table-filter sa-filter-row'}>
                                        <select name="assignee:id" onChange={handleFilter} style={{marginLeft: "8px"}}
                                                className="sa-filter sa-table-float-left">
                                            <option
                                              value={""}>{filters && filters['assignee:id'] ? t("title.ALL") : t("title.ASSIGNEE")}</option>
                                          {activeUsers.map((user, index) => (
                                              <option value={user.id} key={index}
                                                      placeholder={'chain'}>{user.lastName}</option>
                                            )
                                          )}
                                        </select>
                                        <select name="status" onChange={handleFilter}
                                                className="sa-filter sa-table-h-float-l  sa-table-float-left sa-table-m-r-0">
                                         <option
                                           value={""}>{filters && filters.status ? t("title.ALL") : t("title.STATUS")}</option>
                                                <option>{t("title.REPORTED")}</option>
                                            <option>{t("title.ASSIGNED")}</option>
                                            <option value={"IN_PROGRESS"}>{t("title.IN_PROGRESS")}</option>
                                            <option>{t("title.DONE")}</option>
                                            <option>{t('title.FIXED')}</option>
                                        </select>
                                    </span>
                                </span>
                  <span className={'sa-table-icon-flex sa-table-btn-position'}>
                                    <button onClick={exportData}
                                            className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                                        <FeatherIcon
                                          icon={"upload"} className={"sa-table-icon-size"}/> <span
                                      className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
                                    </button>
                    {/*<button className="sa-table-btn-secondary sa-table-float-right" id={'taskAdd'}*/}
                    {/*        onClick={() => {*/}
                    {/*          resetForm();*/}
                    {/*          setIsModalVisible(true);*/}
                    {/*        }}>*/}
                    {/*    <FeatherIcon*/}
                    {/*      icon={"plus"} className={"sa-table-icon-size"} /> <span className={"sa-table-icon"}>{t("button.ADD")}</span>*/}
                    {/*</button>*/}
                                </span>
                </div>
                <div className="sa-table-container">
                  <table className="table table-borderless sa-table-width">
                    <thead>
                    <tr>
                      <th className={"sa-table-head-sticky"}>{t("title.NOTE")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.DEADLINE")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.CATEGORY")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.STATUS")}</th>
                      <th className="sa-table-item-align sa-table-head-sticky"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.length > 0 &&
                      paginatedData.map((tasks, index) => (
                      <tr key={index}>
                        <td className={'sa-table-data'}>{toCapsFirst(tasks.note) || "NA"}</td>
                        <td className={'sa-table-data'}
                            className={(tasks.status !== "DONE") && (tasks.status !== "FIXED") && differenceDays(tasks.endDate) > 0 ? 'text-danger' : ""}>{tasks.endDate || "NA"}</td>
                        <td className={'sa-table-data'}>{tasks.category || "NA"}</td>
                        <td className={'sa-table-data'}> {tasks.status ? tasks.status.replace("_", " ") : "NA"}</td>
                        <td className="sa-table-item-align">
                          <div className={'sa-table-action-flex'}>
                            <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                 title="View">
                              <FeatherIcon
                                onClick={() => {
                                  setSelectedTask(tasks)
                                }}
                                icon={"eye"}/></div>
                            {(hasPermission("DAILY_UPDATE.TASKS", "EDIT") || (tasks.assignee && tasks.assignee.id === getUserId())) &&
                              <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                   title="Edit">
                                <FeatherIcon
                                  onClick={() => {
                                    onEdit(index, tasks);
                                  }} className="table-action" icon={"edit"}/></div>
                            }
                            {(hasPermission("DAILY_UPDATE.TASKS", "DELETE") || (tasks.assignee && tasks.assignee.id === getUserId())) &&
                              <div id={'taskDelete'} className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                   title="Delete">
                                <FeatherIcon
                                  onClick={() => handleDeleteTask(tasks.id, tasks.farmId)}
                                  className="text-red" icon={"trash-2"}/>
                              </div>
                            }
                          </div>
                        </td>
                      </tr>

                    ))}
                    </tbody>
                  </table>
                  {tasks.length === 0 &&
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_TASKS_PLEASE_ADD")}</div>
                    </div>
                  }
                </div>
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    previousClassName={"prev-button"}
                    nextClassName={"next-button"}
                    disabledClassName={"disabled"}
                />
              </div>
            }
            {selectedTask &&
              <FarmTaskView task={selectedTask} onClose={() => setSelectedTask(null)}/>
            }
          </Container>

          {/*{Add and Edit Model}*/}

          {(isModelVisible || isAddTask) && <AddTask onClose={() => {
            setIsModalVisible(false)
            setSelectedTask(null)
            setIsAddTask(false)
            setIsUpdate(!isUpdate)
          }} data={selectedTask} assignee={activeUsers} edit={isModelVisible} farmId={farmId} add={isAddTask}
                                                     updateAvailable={isUpdateAvailable} values={values}
                                                     taskId={taskId}/>}
        </div>
      </div>
    </div>

  )
}

export default AllTasks
