import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import {formatDisplayEnumValue} from "../../utils/Utils";
import {useDispatch} from "react-redux";
import {toggleLoader} from "../../shared/actions/setting";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormHandler from "../../shared/utils/FormHandler";
import {validateDemandCallSummary} from "../../utils/FormValidationRules";

const SupplyRequestCall = (props) => {
  const {t, i18n} = useTranslation();
  const [demandStatus, setDemandStatus] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formErr, setFormErr] = useState(null);
  const [callSummaryList, setCallSummaryList] = useState([]);
  const [requestData, setRequestData] = useState({});

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
  } = FormHandler(demandCallSummary, (values) => validateDemandCallSummary(values, callStatus, demandStatus));

  function demandCallSummary() {
    setIsLoading(true);
  }

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/supply/` + props.viewData.id + `/request`)
      .then(res => {
        setRequestData(res.data.content);
        const foundRequest = res.data.content.find(
          (request) => request.id === props.requestId
        );
        if (foundRequest) {
          setCallStatus(foundRequest.callStatus || "");
          setDemandStatus(foundRequest.demandStatus || "");
        }
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  }, [props.requestId]);


  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/request/` + props.requestId + `/callSummary`)
      .then((res) => {
        setCallSummaryList(res.data.content);
        dispatch(toggleLoader(false));
      });
  }, []);

  const handleDemandStatusChange = (e) => {
    const value = e.target.value;
    setDemandStatus(value);
  };

  const handleCallStatusChange = (e) => {
    const value = e.target.value;
    setCallStatus(value);

    if (value !== "CONTACTED_BUYER") {
      setDemandStatus("");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    let url = `${process.env.REACT_APP_HOST + `/user/` + getUserId() + `/supply-request/` + props.requestId + "/update-call?callStatus=" + callStatus}`;
    if (callStatus !== 'UNABLE_TO_REACH_BUYER') {
      url += `&demandStatus=${demandStatus}`;
    }
    axios.put(url, values)
      .then((res) => {
        toast.success("Call Details created successfully");
        resetFormData();
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
        props.reUpdate();
      });
  }, [isLoading, props.requestId]);

  function resetFormData() {
    props.handleClose();
    setCallStatus("");
    setDemandStatus("");
    setFormErr("");
    initForm({});
    Object.keys(errors).forEach((key) => delete errors[key]);
  }

  return (
    <div className={"sa-popup-bg " + (!props.isRequestCall && "hide")}>
      <div className="sa-popup">
        <div className="sa-popup-scroll">
          <form className={"sa-modal-box-style "} onSubmit={handleSubmit}>
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>{t("title.UPDATE_CALL_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={props.handleClose}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className="sa-popup-content">
              {props.selectedData.callStatus === "CONTACTED_BUYER" ? (
                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t("title.PHONE_CALL_STATUS")}</label>
                    <div className="form-control mb-3">{formatDisplayEnumValue(props.selectedData.callStatus)}</div>
                  </div>
                </div>) : (
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">{t("title.PHONE_CALL_STATUS")}</label>
                    <select
                      value={callStatus}
                      onChange={handleCallStatusChange}
                      className={`form-control mb-3`}
                      name="callStatus"
                    >
                      <option value={""} disabled>
                        {t("title.SELECT")}{`...`}
                      </option>
                      <option value="CONTACTED_BUYER">
                        {t("title.CONTACTED_BUYER")}
                      </option>
                      <option value="UNABLE_TO_REACH_BUYER">
                        {t("title.UNABLE_TO_REACH_BUYER")}
                      </option>
                    </select>
                    {errors.callStatus && (<p className="warning-input-msg m-0">{errors.callStatus}</p>)}
                  </div>
                </div>)}
              <div className="col-md-12">
                <div className="form-group content-view">
                  <label htmlFor="email">{t("title.DEMAND_STATUS")}</label>
                  <select
                    value={demandStatus}
                    onChange={handleDemandStatusChange}
                    className={`form-control mb-3 ${
                      callStatus !== "CONTACTED_BUYER"
                        ? "disable-view-data"
                        : ""
                    }`}
                    name="demandStatus"
                    disabled={callStatus !== "CONTACTED_BUYER"}
                  >
                    <option value={""} disabled>
                      {t("title.SELECT")}{`...`}
                    </option>
                    <option value="DEMAND_CONFIRMED">
                      {t("title.DEMAND_CONFIRMED")}
                    </option>
                    <option value="DEMAND_DECLINED">
                      {t("title.DEMAND_DECLINED")}
                    </option>
                  </select>
                  {errors.demandStatus && (<p className="warning-input-msg m-0">{errors.demandStatus}</p>)}
                </div>
              </div>
              {callStatus === "CONTACTED_BUYER" &&
                demandStatus === "DEMAND_CONFIRMED" && (
                  <div className="ccr-call-details">
                    <div className={"table-container-toggle m-0"}>
                      <hr className="table-toggle-line"/>
                      <div className="table-toggle-heading ml-2">{t("title.CALL_SUMMARY")}</div>
                    </div>
                    <div className="pull-right"
                         style={{marginLeft: "80%"}}
                         onClick={() => {
                           setIsFormVisible(true);
                           initForm({
                             contactedDate: today,
                             negotiatedPrice: props.selectedData.price,
                             negotiatedQuantity: props.selectedData.quantity,
                             deliveryMode: props.selectedData.deliveryPreferences
                           });
                         }
                         }
                    >
                      <button className="btn btn-sa-secondary pis-add" type={"button"}>
                        <FeatherIcon icon={"plus"}/>{" "}
                        <span>{t("button.ADD")}</span>
                      </button>
                      {errors.callSummary && (<p className="warning-input-msg m-0 ">{errors.callSummary}</p>)}
                    </div>
                  </div>
                )}
              {isFormVisible && (
                <div className="single-card py-1 m-1" style={{background: '#F9F9F9'}}>
                  <div className="sa-popup-content">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email">{t("title.CONTACTED_DATE")}</label>
                        <input
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.contactedDate || ""}
                          type="date"
                          className={"form-control mb-3"}
                          placeholder={`dd-mm-yyyy`}
                          name="contactedDate"
                          max={today}
                        />
                        {errors.contactedDate && (<p className="warning-input-msg m-0">{errors.contactedDate}</p>)}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email">{t("title.NEGOTIATED_PRICE")}{` (per kg)`}</label>
                        <input
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.negotiatedPrice || ""}
                          type="number"
                          className="form-control mb-3"
                          placeholder={``}
                          name="negotiatedPrice"
                        />
                        {errors.negotiatedPrice && (<p className="warning-input-msg m-0">{errors.negotiatedPrice}</p>)}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email">{t("title.QUANTITY")}</label>
                        <input
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.negotiatedQuantity || ""}
                          type="number"
                          className="form-control mb-3"
                          placeholder={``}
                          name="negotiatedQuantity"
                        />
                        {errors.negotiatedQuantity && (
                          <p className="warning-input-msg m-0">{errors.negotiatedQuantity}</p>)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">{t("title.DELIVERY_MODE")}</label>
                        <select onBlur={handleOnBlur} onChange={handleChange}
                                value={values.deliveryMode || ''}
                                className={`form-control mb-3`}
                                name="deliveryMode">
                          <option value="" disabled>{t("title.SELECT")}{`...`}</option>
                          <option
                            value="COLLECT_IT_FROM_COLLECTION_CENTER">{t("title.COLLECT_IT_FROM_COLLECTION_CENTER")}</option>
                          <option value="BRING_IT_TO_ME">{t("title.BRING_IT_TO_ME")}</option>
                        </select>
                        {errors.deliveryMode && (<p className="warning-input-msg m-0">{errors.deliveryMode}</p>)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">{t("title.NOTE")}</label>
                        <input
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.note || ""}
                          type="text"
                          className="form-control mb-3"
                          placeholder={``}
                          name="note"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {callStatus === "CONTACTED_BUYER" &&
              demandStatus === "DEMAND_CONFIRMED" && (
                <div>
                  {callSummaryList.map((summary, index) => (
                    <div key={index} className="single-card py-1 m-1">
                      <div className="sa-popup-content">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{t("title.CONTACTED_DATE")}</label>
                            <div className="form-control">{summary.contactedDate || "NA"}</div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{t("title.NEGOTIATED_PRICE")}{` (per kg)`}</label>
                            <div className="form-control">{summary.negotiatedPrice || "NA"}</div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{t("title.QUANTITY")}</label>
                            <div className="form-control">{summary.negotiatedQuantity || "NA"}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{t("title.DELIVERY_MODE")}</label>
                            <div className="form-control">{formatDisplayEnumValue(summary.deliveryMode) || "NA"}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{t("title.NOTE")}</label>
                            <div className="form-control">{summary.note || "NA"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            <div className="sa-popup-btn d-flex ml-3 justify-content-end">
              <button
                type={"button"}
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                style={{border: "1px solid #aaa", marginRight: "10px"}}
                onClick={resetFormData}
              >
                {t("button.CANCEL")}
              </button>
              <button
                className={`sa-popup-secondary-btn-style`}
                type="submit"
              >
                {t("button.SAVE")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupplyRequestCall