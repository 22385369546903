import React, {useEffect, useState} from "react";
import {camelToReadable, formatDisplayEnumValue, toCapsFirst} from "../../utils/Utils";
import Container from "react-bootstrap/Container";
import FormHandler from "../../shared/utils/FormHandler";
import {validateOrderUpdate} from "../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../shared/actions/setting";
import {useDispatch} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import FilterHandler from "../../shared/utils/CombinedFilterHandler";
import DealsSummary from "./deal-summary";

const Deals = () => {
  const [orderList, setOrderList] = useState([]);
  const [farmId, setFarmId] = useState(null);
  const [orderLogList, setOrderLogList] = useState([]);
  const [orderListUnFiltered, setOrderListUnFiltered] = useState([]);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLogVisible, setIsLogVisible] = useState(false);
  const [isRequestViewVisible, setIsRequestViewVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const {t, i18n} = useTranslation();
  const [fpoList, setFpoList] = useState('');
  const [corporateId, setCorporateId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const dispatch = useDispatch();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
  } = FormHandler(orderRequest, validateOrderUpdate);

  function orderRequest() {
    setIsLoading(true);
  }

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/orders`)
      .then(res => {
        setOrderList(res.data.content);
        setOrderListUnFiltered(res.data.content)
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (!farmId) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(res => {
        setCorporateId(res.data.content.corporateId)
        dispatch(toggleLoader(false));
      });
  }, [farmId]);

  useEffect(() => {
    if (!selectedData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/activity-log` + '?objectId=' + selectedData.id + `&order=DESC&pageSize=10&pageIndex=0`)
      .then(res => {
        setOrderLogList(res.data.content.content);
      }).catch(error => {
      console.error("Error fetching logs", error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [selectedData]);

  useEffect(() => {
    if (!corporateId) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + corporateId + `/system-user?roleId=900`)
      .then(res => {
        setFpoList(res.data.content);
      }).catch(error => {
      console.error("Error fetching users", error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [corporateId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let requestStatus = values.requestStatus;
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/order/` + values.orderId + `?requestStatus=` + requestStatus)
      .then(res => {
        toast.success("Order updated successfully");
        setOrderList(orderList.map(order => {
          if (order.orderId === values.orderId) {
            return {...order, requestStatus: values.requestStatus};
          }
          return order;
        }));
        resetFormData()
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
      });

  }, [isLoading]);

  const handleSendMail = (fpoId) => {
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/order/' + orderId + '/fpo/' + fpoId + '/sendmail')
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/order/` + orderId + `?requestStatus=FPO_PROCESSING`)
      .then(res => {
        toast.success("Mail Send successfully");
        setOrderList(orderList.map(order => {
          if (order.orderId === orderId) {
            return {...order, requestStatus: 'FPO_PROCESSING'};
          }
          return order;
        }));
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  };

  function resetFormData() {
    setIsModalVisible(false);
    setFormErr("");
    initForm({})
    Object.keys(errors).forEach(key => delete errors[key]);
  }

  const fieldsToFilter = ['item'];
  const {
    handleSearch,
    filteredList,
    handleFilter,
    filters
  } = FilterHandler(setFilteredList, orderListUnFiltered, fieldsToFilter);

  function setFilteredList() {
    setOrderList(filteredList);
    setOrderListUnFiltered(orderListUnFiltered);
  }

  function handleView(log) {
    setSelectedItem(JSON.parse(log.object));
  }

  function getValue(key) {
    if (typeof selectedItem[key] === 'object') {
      return "OBJECT";
    }
    return formatDisplayEnumValue(selectedItem[key]);
  }

  const handleClose = () => {
    if (isLogVisible) {
      setIsLogVisible(false);
    }
    if (isRequestViewVisible) {
      setIsRequestViewVisible(false);
    }
    if (isInfoVisible) {
      setIsInfoVisible(false);
    }
  };

  return (
    <div>
      <Container>
      <div className="single-card overflow-visible m-rl-m-8  p-a-16">
      <div className={'sa-table-flex sa-table-position'} style={{marginBottom: 8}}>
        <span hidden={isViewVisible}>
          <span className="sa-table-btn-mute sa-search-align sa-table-float-left">
                <input
                  className={'sa-table-search sa-table-search-point font-14'}
                  onChange={handleSearch}
                  autoComplete={"off"}
                  type="text"
                  placeholder={t("placeHolder.SEARCH")}
                  name="search"
                />
                <FeatherIcon className={"sa-search-icon"} icon={"search"} width={'16px'}/>
            </span>
        </span>
        </div>
        <div className="sa-table-container ccr-user-calc-height" hidden={isViewVisible}>
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.ORDER_ID")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.SUPPLIER")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.BUYER")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.PRODUCT")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.QUANTITY")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.PRICE")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.NEEDED_DEADLINE")}</th>
              {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
              <th className="sa-table-item-align sa-table-head-sticky"></th>
              {/* } */}
            </tr>
            </thead>
            <tbody>
            {orderList && orderList.sort((a, b) => {
              const numA = parseInt(a.orderId.replace('ORD', ''), 10);
              const numB = parseInt(b.orderId.replace('ORD', ''), 10);
              return numB - numA;
            }).map((order, index) => (
              <tr key={index}>
                <td className={'sa-table-data'}>{order.orderId}</td>
                <td className={'sa-table-data'}>{toCapsFirst(order.supplierInfo.lastName)}</td>
                <td className={'sa-table-data'}>{toCapsFirst(order.buyerInfo.lastName)}</td>
                <td className={'sa-table-data'}>{(order.item)}</td>
                <td
                  className={'sa-table-data'}>{(order.unit && order.unit !== "None") ? order.quantity + ` ` + order.unit : order.quantity}</td>
                <td className={'sa-table-data'}>Rs. {order.price}</td>
                <td className={'sa-table-data'}>{order.interestDeadline}</td>
                {/* {hasPermission("BUYER_PROFILE.SUPPLY_AVAILABILITY", "ADD") && */}
                <td className="sa-table-item-align">
                  <div className={'sa-table-action-flex'}>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.LOGS")}
                         onClick={() => {
                           setSelectedData(order);
                           setIsLogVisible(true);
                         }}><FeatherIcon className={"table-action"}
                                         icon={"airplay"}/></div>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.EDIT")}>
                      <FeatherIcon
                        onClick={() => {
                          setIsModalVisible(true);
                          Object.keys(errors).forEach(k => errors[k] = "");
                          values.orderId = order.orderId;
                          values.requestStatus = order.requestStatus;
                          values.ccr = order.ccr;
                          setFormErr("");
                        }}
                        className="table-action" icon={"edit"}/></div>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.INFO")}
                         onClick={() => {
                           setSelectedData(order);
                           setIsInfoVisible(true);
                           console.log("selectedData", selectedData);
                         }}><FeatherIcon className={"table-action"}
                                         icon={"info"}/></div>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.MAIL")}
                         onClick={() => {
                           setFarmId(order.supplierInfo.id);
                           setOrderId(order.orderId);
                           setIsRequestViewVisible(true);
                         }}><FeatherIcon
                      className={`table-action ${order.requestStatus === 'FPO_APPROVED' ? 'text-green' : (order.requestStatus === "FPO_NOT_APPROVED" ? "text-red" : null)}`}
                      icon={"mail"}/></div>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.VIEW")}
                         onClick={() => {
                           setIsSummaryVisible(true);
                           setOrderData(order);
                         }}><FeatherIcon className={`table-action`}
                                         icon={"inbox"}/></div>
                  </div>
                </td>
                {/* } */}
              </tr>
            ))}
            </tbody>
          </table>
          {orderList && orderList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_DEALS")}</div>
            </div>)
          }
        </div>
        {isViewVisible && <div>
          <div className={'d-flex align-items-center pdf-hide'}>
            <div className={"mb-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => {
                setIsViewVisible(false)
              }}
                           className={"sa-cursor"}/> {t("title.ORDERS")}
            </div>
          </div>
        </div>}
      </div>
      </Container>

      <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.UPDATE_ORDER_STATUS")}</span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="col-md-12" style={{paddingBottom: '10px'}}>
              <label className="col-md-6">{t("title.CCR_HANDLING_THE_ORDER")} : {toCapsFirst(values.ccr)}</label>
              <label className="col-md-6">{t("title.ORDER_ID")} : {values.orderId}</label>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="email">{t("title.ORDER_STATUS")}</label>
                  <select onBlur={handleOnBlur} onChange={handleChange}
                          value={values.requestStatus || ''}
                          className={`form-control`}
                          name="requestStatus">
                    <option value="ORDER_CONFIRMED">{t("title.ORDER_CONFIRMED")}</option>
                    <option value="CANCELLED">{t("title.CANCELLED")}</option>
                    <option value="FPO_PROCESSING">{t("title.FPO_PROCESSING")}</option>
                    <option value="FPO_APPROVED">{t("title.FPO_APPROVED")}</option>
                    <option value="PAYMENT_IN_PROGRESS">{t("title.PAYMENT_IN_PROGRESS")}</option>
                    <option value="PAYMENT_DONE">{t("title.PAYMENT_DONE")}</option>
                    <option value="DELIVERY_IN_PROGRESS">{t("title.DELIVERY_IN_PROGRESS")}</option>
                    <option value="DELIVERED">{t("title.DELIVERED")}</option>
                    <option value="FPO_NOT_APPROVED">{t("title.FPO_NOT_APPROVED")}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">{t("title.FPO_CONFIRMATION_STATUS")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange} disabled
                         value={
                           values.requestStatus === 'FPO_PROCESSING'
                             ? t("title.FPO_PROCESSING")
                             : values.requestStatus === 'FPO_APPROVED' ||
                             values.requestStatus === 'PAYMENT_IN_PROGRESS' ||
                             values.requestStatus === 'PAYMENT_DONE' ||
                             values.requestStatus === 'DELIVERY_IN_PROCESSING' ||
                             values.requestStatus === 'DELIVERED'
                               ? formatDisplayEnumValue("FPO_APPROVED")
                               : t("title.NOT_APPROVED")
                         }
                         type="text"
                         className={`form-control`}
                         name="status"/>
                </div>
              </div>
            </div>

            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="sa-popup-btn">
              <button

                className="sa-popup-secondary-btn-style">
                {t("button.SAVE")}</button>
            </div>
          </form>
        </div>
      </div>

      <div className={"sa-popup-bg " + (!isLogVisible && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header" style={{overflow: 'visible'}}>
              <span className={'sa-model-heading'}>{t("title.ACTIVITY_LOG")}</span>
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content m-b-16">
              {isLogVisible && (
                <>
                  <div
                   className="m-b-8" style={{paddingLeft: '10px'}}>{t("title.CCR")} : {(selectedData.ccr ? toCapsFirst(selectedData.ccr) : "NA")}</div>
                  <table className="table table-borderless sa-table-width">
                    <thead>
                    <tr>
                      <th className={"sa-table-head-sticky"}>{t("title.TIME")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.TYPE")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.ACTION_BY")}</th>
                      <th className="sa-table-head-sticky">{t("title.DESCRIPTION")}</th>
                      <th className="sa-table-item-align sa-table-head-sticky">{t("title.ACTION")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderLogList && orderLogList.length > 0 && orderLogList.map((log, index) => (
                      <tr key={index}>
                        <td className={'sa-table-data'}>{log.time}</td>
                        <td className={'sa-table-data'}>{log.type}</td>
                        <td className={'sa-table-data'}>{log.userInfo.lastName}</td>
                        <td className={'sa-table-data'}>{log.description}</td>
                        <td className="sa-table-item-align">
                          <div className={'sa-table-action-flex'}>
                            <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title="View">
                              <FeatherIcon
                                onClick={() => {
                                  handleView(log);
                                }}
                                className="table-action" icon={"eye"}/></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  {orderLogList && orderLogList.length === 0 && (<div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ACTIVITIES")}</div>
                  </div>)}
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className={"sa-popup-bg " + (!isRequestViewVisible && 'hide')}>
        <div className="sa-popup">
          <div className={'sa-modal-box-style'}>
            <div className="sa-popup-header" style={{marginBottom: '0px'}}>
              <span
                className={'sa-model-heading'}>{t("title.REQUEST_FOR_FPO_CONFIRMATION")}</span>
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {fpoList && fpoList.length !== 0 ? (
                <>
                  <div className="info-content">
                    <FeatherIcon icon="info" className="info-icon" />{t("info.REQUEST_FOR_FPO_CONFIRMATION")}
                  </div>
                  <div className="sa-popup-content" style={{paddingTop: '20px', paddingBottom: '10px'}}>
                    <div className="col-md-4">
                      <div className="content-view">
                        <label className={"labelSize"}>{t("title.FPO_NAME")}</label>
                        <div className="view-data">{fpoList[0].lastName ? fpoList[0].lastName : "NA"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="content-view">
                        <label className={"labelSize"}>{t("title.FPO_EMAIL")}</label>
                        <div className="view-data">{fpoList[0].email ? fpoList[0].email : "NA"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="content-view">
                        <label className={"labelSize"}>{t("title.ADDRESS")}</label>
                        <div className="view-data">{fpoList[0].address ? fpoList[0].address : "NA"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="content-view">
                        <label className={"labelSize"}>{t("title.CONTACT_NO")}</label>
                        <div className="view-data">{fpoList[0].mobile ? fpoList[0].mobile : "NA"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="sa-popup-btn">
                    <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                            style={{border: '1px solid #aaa', marginRight: '10px'}}
                            onClick={handleClose}>{t("button.CANCEL")}
                    </button>
                    <button className="sa-popup-secondary-btn-style" type="button"
                            onClick={() => {
                              handleSendMail(fpoList[0].id);
                              handleClose();
                            }}>
                      {t("button.SEND_DETAILS")}
                    </button>
                  </div>
                </>
              ) :
              <div className="empty-results">
                <FeatherIcon icon="info"/>
                <div className="empty-results-text">{t("emptyMsg.NO_FPO_FOUND")}</div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className={"sa-popup-bg " + (!isInfoVisible && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.ORDER_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{selectedData && selectedData.supplyId ? t("title.SUPPLY_ID") : t("title.DEMAND_ID")}</label>
                  <div className="view-data">{selectedData ? selectedData.supplyId || selectedData.demandId : "NA"}
                  </div>
              </div>
            </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.SUPPLIER")}</label>
                  <div className="view-data">{selectedData ? selectedData.supplierInfo.lastName : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.BUYER")}</label>
                  <div className="view-data">{selectedData ? selectedData.buyerInfo.lastName : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.CCR")}</label>
                  <div className="view-data">{selectedData ? selectedData.ccr : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.PRODUCT")}</label>
                  <div className="view-data">{selectedData ? selectedData.item : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.PRICE")}</label>
                  <div className="view-data">{selectedData ? selectedData.price : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.QUANTITY")}</label>
                  <div className="view-data">{selectedData ? selectedData.quantity : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.QUALITY_PARAMETERS")}</label>
                  <div className="view-data">{selectedData ? selectedData.qualityParameters : "NA"}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-view">
                  <label className={"labelSize"}>{t("title.DELIVERY_MODE")}</label>
                  <div className="view-data"
                    >{selectedData ? formatDisplayEnumValue(selectedData.deliveryPreferences) : "NA"}
                </div>
              </div>
            </div>
            </div>
          </form>
        </div>
      </div>

      {/*-----------------------------------------------------------------View AuditLogPopup Details---------------------------------------------------            */}
      {selectedItem && <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.VIEW_AUDIT_LOG_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={() => {
                setSelectedItem(null)
              }}><FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className="sa-popup-content">
              {Object.entries(selectedItem).map(([key, value]) =>
                (<div className="col-md-4" key={key + "archive"}>
                  <div className="form-group m-b-16 view-popup-label">
                    <label className={"labelSize"}>{camelToReadable(key)} </label>
                    <label className="lable-design">
                      <label className="labelSize1 limit-charater-xindicateViewLabel"
                             title={getValue(key)}>{getValue(key)}</label></label>
                  </div>
                </div>)
              )}
            </div>
          </form>
        </div>
      </div>}

      {isSummaryVisible && (
        <DealsSummary isSummaryVisible={isSummaryVisible}
                      handleClose={() => {
                        setIsSummaryVisible(false);
                      }}
                      orderData={orderData}/>

      )}
    </div>
  )
};
export default Deals
