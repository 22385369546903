import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {procurementAdd} from "../../utils/FormValidationRules";
import FormHandler from "../../shared/utils/FormHandler";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";

const AddProcurements = (props) => {

  const {t, i18n} = useTranslation();
  const [cropNames, setCropNames] = useState([]);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm
  } = FormHandler(procurement, procurementAdd);

  function procurement() {
    props.onChange(values);
  }

  const onCropSelect = (e) => {
    if (values.category !== e.target.value) {
      setValue({item: ""})
    }
    setValue({category: e.target.value})
    setCropNames(_.pluck(props.crops.filter(item => item.category === e.target.value), 'cropName'))
  }

  useEffect(() => {
    if (props.selectedItem) {
      setValue(props.selectedItem);
      if (props.crops) {
        setCropNames(_.pluck(props.crops.filter(item => item.category === props.selectedItem.category), 'cropName'));
      }
    }
  }, [props.selectedItem]);

  return (
    <div>
      <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form onSubmit={handleSubmit} className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{props.selectedItem ? t("title.EDIT_PROCUREMENT") : t("title.ADD_PROCUREMENT")}</span>
              <div className="sa-popup-close-icon"><FeatherIcon
                onClick={() => {
                  props.onClose();
                }}
                className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.CATEGORY")}</label>
                  <select
                    onBlur={handleOnBlur} onChange={onCropSelect}
                    value={values.category || ''} type="text"
                    className={`form-control ${errors.category && "warning-input"}`}
                    name="category"
                    placeholder={t("title.SELECT_CAREGORY")}
                  >
                    <option value={""}>{t("title.SELECT_CAREGORY")}</option>
                    {props.category.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  {errors.category && (<p className="warning-input-msg ">{errors.category}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.CROP")}</label>
                  <select
                    onBlur={handleOnBlur} onChange={handleChange}
                    value={values.item || ''} type="text"
                    className={`form-control ${errors.item && "warning-input"}`}
                    name="item"
                    placeholder={t("placeHolder.SELECT_CROP_NAME")}
                  >
                    <option value={""}>{t("placeHolder.SELECT_CROP_NAME")}</option>
                    {cropNames.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  {errors.item && (<p className="warning-input-msg ">{errors.item}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.REQUIREMENT")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.requirement || ''} type="text"
                         className={`form-control`}
                         name="requirement"
                         placeholder={t("placeHolder.ENTER_REQUIREMENT")}/>
                </div>
              </div>
            </div>
            <div className="sa-popup-btn m-t-16">
              <button id="btnCancel" type={"button"} onClick={() => {
                props.onClose();
              }}
                      className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style">{t("button.CANCEL")}
              </button>
              <button id="btnAdd"
                      className="sa-popup-secondary-btn-style">{!props.selectedItem ? t("button.ADD") : t("button.UPDATE")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

};
export default AddProcurements;
