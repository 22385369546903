import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ValveMap from "../smart-farm/farm/valve-map";
import FarmAlertLevelPop from "./farm-alert-level-pop";
import ToggleLayout from "../toggle-layout";
import danger from '../../images/alert-triangle.svg'
import valve from '../../images/add-valve.svg'
import valveMap from '../../images/icons/fi-rr-location-alt.svg'
import actuatorIcon from '../../images/icons/fi-rr-smartphone.svg'
import sensor from '../../images/icons/router-line.svg'
import upload from '../../images/icons/upload-blue.svg'
import Actuator from "../smart-farm/farm/actuator";
import PlotSettingAlert from "./plot/plot-setting-alert";
import DeviceSetting from "./plot/device-setting";
import ValveSetting from "./plot/valve-setting";
import KitSetting from "./plot/kit-setting";
import SenzagroVisionKitSetting from "./plot/senzagro-vision-kit-setting";
import UploadKitData from "./plot/upload-kit-data";
import FeatherIcon from "feather-icons-react";
import FarmAlertLog from "./farm-alert-log";
import {useTranslation} from "react-i18next";
import {getFindLocation, getSelectedCoordinate, getSelectedSensor,} from "../../actions/sensor";
import {changeToggle} from "../../shared/actions/setting";
import {TOGGLE} from "../../utils/Enum";


const FarmSetting = (props) => {
  const {t, i18n} = useTranslation();

  const {farmId} = useParams();
  const [selectedDropdownPlotId, setSelectedDropdownPlotId] = useState(null);
  const dispatch = useDispatch();
  const [kit, setKit] = useState(null);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);

  const findDeviceLocation = useSelector((state) => {
    return state.sensor.findLocation;
  });

  function onClose() {
    dispatch(getFindLocation(false));
    dispatch(getSelectedCoordinate({}));
    dispatch(changeToggle(TOGGLE.LIST));
    dispatch(getSelectedSensor(-1));
  }

  // let selectedPlot = useSelector(state => {
  //     return state.plotList.selectedPlot;
  // });

  // useEffect(() => {
  //     if (!selectedDropdownPlotId) {
  //         return;
  //     }
  //
  //     dispatch(toggleLoader(true));
  //     axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + selectedDropdownPlotId)
  //         .then(function (response) {
  //             dispatch(getSelectedPlot(response.data.content));
  //         })
  //         .catch(function (error) {
  //             if (error.response && error.response.status === 422) {
  //             }
  //         })
  //         .finally(() => {
  //             dispatch(toggleLoader(false));
  //         });
  //
  // }, [selectedDropdownPlotId])


  //
  //   useEffect(() => {
  //       if (!selectedPlot.kitId) {
  //           return;
  //       }
  //       axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
  //           .then(res => {
  //               setKit({...res.data.content});
  //               dispatch(toggleLoader(false))
  //           }).catch(error => {
  //               if (error.response && error.response.status === 422) {
  //                   // toast.error(error.response.data.message);
  //               }
  //               dispatch(toggleLoader(false))
  //           }
  //       ).finally(
  //       );
  //
  //   }, [selectedPlot.kitId]);

  return (
    <div className="">
      <div className={"farm-settings-blur"} hidden={!findDeviceLocation}>
        <div className={"farm-settings-blur-inner"}>
          <FeatherIcon className={"whit-text m-b-8"} icon={"map-pin"}/>
          <div className={"whit-text"}>
            {" "}
            Please select a location in the map
          </div>
          <button
            className="btn whit-text btn-sa-success w-140p"
            onClick={onClose}
          >
            {" "}
            {t("button.CANCEL")}
          </button>
        </div>
      </div>
      <Container className={'pb-3'}>

        <ToggleLayout image={danger} title={t("title.ALERT_SETTINGS")} dropDown={true} kit={true}
                      onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)} value={selectedDropdownPlotId}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId || selectedDropdownPlotId === 'FARM' ?
            <FarmAlertLevelPop/>
            :
            <PlotSettingAlert plotId={selectedDropdownPlotId}/>
          }
        </ToggleLayout>

        <ToggleLayout image={actuatorIcon} title={t("title.ACTUATOR")} dropDown={false}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={2}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          <Actuator/>
        </ToggleLayout>

        <ToggleLayout image={valveMap} title={t("title.VALVE_MAP")} dropDown={false}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={3}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          <ValveMap farmId={farmId}/>
        </ToggleLayout>

        <ToggleLayout image={sensor} title={t("title.DEVICES")} Farm={true} dropDown={true}
                      onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)} value={selectedDropdownPlotId}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={4}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId ?
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")}</div>
            </div> :
            <DeviceSetting type={selectedDropdownPlotId === "FARM" ? "FARM" : "PLOT"}
                           plotId={selectedDropdownPlotId === "FARM" ? farmId : selectedDropdownPlotId}
                           farmId={farmId}/>}
        </ToggleLayout>

        <ToggleLayout image={valve} title={t("title.VALVE_SETTINGS")} Farm={true} dropDown={true}
                      onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)} value={selectedDropdownPlotId}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={5}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId ?
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")} </div>
            </div> :
            <ValveSetting type={selectedDropdownPlotId === "FARM" ? "FARM" : "PLOT"}
                          plotId={selectedDropdownPlotId === "FARM" ? farmId : selectedDropdownPlotId}
                          farmId={farmId}/>}
        </ToggleLayout>
        <ToggleLayout imgType={"feather"}
                      feather={"hard-drive"}
                      title={t("title.KIT_SETTINGS")}
                      Farm={true} dropDown={true} onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)}
                      value={selectedDropdownPlotId} visibleToggleIndex={visibleToggleIndex} toggleIndex={6}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId ?
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")} </div>
            </div> :
            <KitSetting type={selectedDropdownPlotId === "FARM" ? "FARM" : "PLOT"}
                        plotId={selectedDropdownPlotId === "FARM" ? farmId : selectedDropdownPlotId} farmId={farmId}/>}
        </ToggleLayout>

        <ToggleLayout imgType={"feather"}
                      feather={"package"}
                      title={t("title.VISION_KIT_SETTINGS")}
                      Farm={false} dropDown={true} onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)}
                      value={selectedDropdownPlotId} visibleToggleIndex={visibleToggleIndex} toggleIndex={7}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId ?
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")} </div>
            </div> :
            <SenzagroVisionKitSetting type={"PLOT"}
                                      plotId={selectedDropdownPlotId} farmId={farmId}/>}
        </ToggleLayout>

        <ToggleLayout image={upload} title={t("title.UPLOAD_BULK_DATA")} Farm={false} dropDown={true}
                      onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)} value={selectedDropdownPlotId}
                      visibleToggleIndex={visibleToggleIndex} toggleIndex={8}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          {!selectedDropdownPlotId || selectedDropdownPlotId === 'FARM' ?
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")} </div>
            </div> :
            <UploadKitData plotId={selectedDropdownPlotId}/>
          }
        </ToggleLayout>

        <ToggleLayout image={danger} title={t("title.ALERT_LOG")} visibleToggleIndex={visibleToggleIndex}
                      toggleIndex={9}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
          <FarmAlertLog/>
        </ToggleLayout>

      </Container>
    </div>
  )
};


export default FarmSetting;
