import React, {useEffect, useState} from 'react';
import FormHandler from "../shared/utils/FormHandler";
import {useDispatch} from "react-redux";
import Dlogo from "../images/dunlogo.png";
import Logo from "../images/logo.png";
import validateRegister from "../utils/FormValidationRules";
import {toggleLoader} from "../shared/actions/setting";
import axios from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import codeList from "../utils/code.json";
import {useHistory} from "react-router-dom";

function Register(props) {

  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const {
    values,
    errors,
    setValue,
    handleChange,
    handleOnBlur,
    handleSubmit,
  } = FormHandler(register, validateRegister);

  function register() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let temData = {...values}
    delete temData.confirmPassword;
    delete temData.hide;
    temData.code = values.code.toUpperCase();
    temData.userRoleId = codeList[temData.code]?.userRoleId;
    temData.references = [codeList[temData.code]?.references];
    temData.host = window.location.protocol + '//' + window.location.host + '/sign-up';
    delete temData.code;
    dispatch(toggleLoader(true));
    axios.post(`${process.env.REACT_APP_HOST}/register`, temData)
      .then((response) => {
        toast.success(t("successMsg.USER_CREATED_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
        history.push("/login");
      }).catch((error) => {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      }
    }).finally(() => {
      setIsLoading(false);
      dispatch(toggleLoader(false));
    })
  }, [isLoading]);


  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="login-logo">
            {process.env.REACT_APP_DOMAIN === "DUNYA" ? (
              <img className="dunya-logo-image" src={Dlogo} alt="logo"/>
            ) : (
              <img className="login-logo-image" src={Logo} alt="logo"/>
            )}
          </div>
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group m-b-16">
              <label htmlFor="email">Name</label>
              <input type="text" value={values.lastName || ''} onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.lastName ? "form-control warning-input" : "form-control"}
                     name="lastName" placeholder="Enter your Name"/>
              {errors.lastName && (
                <p className="warning-input-msg">{errors.lastName}</p>)}
            </div>
            <div className="form-group m-b-16">
              <label htmlFor="email">Email</label>
              <input type="text" value={values.email || ''} onChange={handleChange} onBlur={handleOnBlur}
                     className={errors.email ? "form-control warning-input" : "form-control"}
                     name="email" placeholder="Enter your Email"/>
              {errors.email && (
                <p className="warning-input-msg">{errors.email}</p>)}
            </div>
            <div className="form-group m-b-16">
              <label htmlFor="email">Mobile</label>
              <input type="text" value={values.mobile || ''} onChange={handleChange} onBlur={handleOnBlur}
                     className={errors.mobile ? "form-control warning-input" : "form-control"}
                     name="mobile" placeholder="Enter your Mobile"/>
              {errors.mobile && (
                <p className="warning-input-msg">{errors.mobile}</p>)}
            </div>
            <div className="form-group m-b-16">
              <label htmlFor="email">Code</label>
              <input type="text" value={values.code || ''} onChange={handleChange} onBlur={handleOnBlur}
                     className={errors.code ? "form-control warning-input" : "form-control"}
                     name="code" placeholder="Enter your Code"/>
              {errors.code && (
                <p className="warning-input-msg">{errors.code}</p>)}
            </div>
            {errMsg && (<div id={'loginWarning'} className={"login-warning-msg"}>{errMsg}</div>)}

            <button className="btn btn-sa-primary w-100 m-b-14" type={"submit"}>LOGIN</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;