import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import Container from "react-bootstrap/Container";
import ToggleLayout from "../toggle-layout";
import {toggleLoader} from "../../shared/actions/setting";
import {getUserId} from "../../shared/utils/SharedAuthentication";

const DealsSummary = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [buyerDetail, setBuyerDetail] = useState(true);
  const [supplierDetail, setSupplierDetail] = useState(false);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);
  const [buyerReceiptData, setBuyerReceiptData] = useState([]);
  const [supplierReceiptData, setSupplierReceiptData] = useState([]);
  const [activeTab, setActiveTab] = useState("buyer");

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/request/` + props.orderData.id + `/buyer-receipt`)
      .then((res) => {
        setBuyerReceiptData(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.orderData.id]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/request/` + props.orderData.id + `/supplier-receipt`)
      .then((res) => {
        setSupplierReceiptData(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.orderData.id]);

  return (
    <>
      <div className={"sa-popup-bg " + (!props.isSummaryVisible && "hide")}>
        <div className="sa-popup">
          <form className={"sa-modal-box-style"}>
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>{t("title.RECEIPT_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={props.handleClose}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content" style={{flexDirection: "column"}}>
              <div className={`d-flex text-center pull-right`} style={{justifyContent: "space-around"}}>
                <div
                  onClick={() => {
                    setActiveTab("buyer");
                    setBuyerDetail(true);
                    setSupplierDetail(false);
                  }}
                  className={`col-md-6 ${activeTab === "buyer" ? 'activeTabStyle' : {}}`}
                >
                  <div id={"tabSetting"} className="farm-header-toggles"
                       style={{border: "none", fontSize: '14px', fontWeight: '600'}}>
                    <span className="">{t("title.BUYER")}</span>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setActiveTab("supplier");
                    setSupplierDetail(true);
                    setBuyerDetail(false);
                  }}
                  className={`col-md-6 ${activeTab === "supplier" ? 'activeTabStyle' : {}}`}
                >
                  <div id={"tabSetting"} className="farm-header-toggles"
                       style={{border: "none", fontSize: '14px', fontWeight: '600'}}>
                    <span className="">{t("title.SUPPLIER")}</span>
                  </div>
                </div>
              </div>
              {activeTab === "buyer" && buyerDetail && (
                <div>
                  <Container className={"pb-3"}>
                    <ToggleLayout imgType={"feather"} feather={"shopping-cart"} title={t("title.ORDER_DETAILS")}
                                  visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      <div className={"row farm-info-set"}>
                        <div className={"col-md-4 col-lg-4"}>
                          <div className={"farm-info-title"}>{t("title.ORDER_ID")}</div>
                          <div className={"farm-info-data"}>{props.orderData.orderId}</div>
                        </div>
                        <div className={"col-md-4 col-lg-4"}>
                          <div className={"farm-info-title"}>{t("title.NEEDED_DEADLINE")}</div>
                          <div className={"farm-info-data"}>{props.orderData.interestDeadline}</div>
                        </div>
                        <div className={"col-md-4 col-lg-4"}>
                          <div className={"farm-info-title"}>{t("title.CCR_HANDLING_THE_ORDER")}</div>
                          <div className={"farm-info-data"}>{props.orderData.ccr}</div>
                        </div>
                      </div>
                    </ToggleLayout>

                    <ToggleLayout imgType={"feather"} feather={"box"} title={t("title.PRODUCT_DETAILS")}
                                  dropDown={false} visibleToggleIndex={visibleToggleIndex} toggleIndex={2}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      <table className="table table-borderless sa-table-width">
                        <thead>
                        <tr>
                          <th className={"sa-table-head-sticky"}>{t("title.PRODUCT")}</th>
                          <th className="sa-table-head-sticky">{t("title.QUANTITY")}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.PRICE")}{`(per unit)`}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.TOTAL")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {buyerReceiptData.productDetails && buyerReceiptData.productDetails.length > 0 &&
                          buyerReceiptData.productDetails.map((order, index) => (
                            <tr key={index}>
                              <td className={"sa-table-data"}>{props.orderData.item}</td>
                              <td className={"sa-table-data"}>{order.quantity} kg</td>
                              <td className={"sa-table-data"}>Rs. {order.price}</td>
                              <td className={"sa-table-data"}>Rs. {order.price * order.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                        {buyerReceiptData.productDetails && buyerReceiptData.productDetails.length > 0 && (
                          <tfoot>
                          <tr>
                            <td colSpan="3" className={"sa-table-data"}><strong>{t("title.TOTAL_PAYMENT")}</strong></td>
                            <td className={"sa-table-data"}>
                              Rs. {buyerReceiptData.productDetails.reduce((sum, order) => sum + (order.price * order.quantity), 0)}
                            </td>
                          </tr>
                          </tfoot>
                        )}
                      </table>
                    </ToggleLayout>
                    <ToggleLayout imgType={"feather"} feather={"truck"} title={t("title.COLLECTION_CENTER_DETAILS")}
                                  visibleToggleIndex={visibleToggleIndex} toggleIndex={3}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      {buyerReceiptData.collectionCenterDetails && buyerReceiptData.collectionCenterDetails.length > 0 &&
                        buyerReceiptData.collectionCenterDetails.map((center, index) => (
                          <div key={index} className={"row farm-info-set single-card py-1 m-1"}
                               style={{background: "#F9F9F9"}}>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.COLLECTION_CENTER_NAME")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.corporateName || "NA"}</div>
                            </div>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.QUANTITY")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.totalQuantity || "NA"}</div>
                            </div>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.ADDRESS")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.corporateAddress || "NA"}</div>
                            </div>
                          </div>
                        ))}
                    </ToggleLayout>
                  </Container>
                </div>
              )}

              {activeTab === "supplier" && supplierDetail && (
                <div>
                  <Container className={"pb-3"}>
                    <ToggleLayout imgType={"feather"} feather={"shopping-cart"} title={t("title.ORDER_DETAILS")}
                                  visibleToggleIndex={visibleToggleIndex} toggleIndex={4}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      <div className={"row farm-info-set"}>
                        <div className={"col-md-4 col-lg-3"}>
                          <div className={"farm-info-title"}>{t("title.ORDER_ID")}</div>
                          <div className={"farm-info-data"}>{props.orderData.orderId}</div>
                        </div>
                        <div className={"col-md-4 col-lg-3"}>
                          <div className={"farm-info-title"}>{t("title.NEEDED_DEADLINE")}</div>
                          <div className={"farm-info-data"}>{props.orderData.interestDeadline}</div>
                        </div>
                        <div className={"col-md-4 col-lg-3"}>
                          <div className={"farm-info-title"}>{t("title.CCR_HANDLING_THE_ORDER")}</div>
                          <div className={"farm-info-data"}>{props.orderData.ccr}</div>
                        </div>
                      </div>
                    </ToggleLayout>

                    <ToggleLayout imgType={"feather"} feather={"box"} title={t("title.PRODUCT_DETAILS")}
                                  dropDown={false} visibleToggleIndex={visibleToggleIndex} toggleIndex={5}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      <table className="table table-borderless sa-table-width">
                        <thead>
                        <tr>
                          <th className={"sa-table-head-sticky"}>{t("title.PRODUCT")}</th>
                          <th className="sa-table-head-sticky">{t("title.QUANTITY")}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.PRICE")}{`(per unit)`}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.TOTAL")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {supplierReceiptData.productDetails && supplierReceiptData.productDetails.length > 0 &&
                          supplierReceiptData.productDetails.map((order, index) => (
                            <tr key={index}>
                              <td className={"sa-table-data"}>{props.orderData.item}</td>
                              <td className={"sa-table-data"}>{order.quantity} kg</td>
                              <td className={"sa-table-data"}>Rs. {order.price}</td>
                              <td className={"sa-table-data"}>Rs. {order.price * order.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                        {supplierReceiptData.productDetails && supplierReceiptData.productDetails.length > 0 && (
                          <tfoot>
                          <tr>
                            <td colSpan="3" className={"sa-table-data"}><strong>{t("title.TOTAL_PAYMENT")}</strong></td>
                            <td className={"sa-table-data"}>
                              Rs. {supplierReceiptData.productDetails.reduce((sum, order) => sum + (order.price * order.quantity), 0)}
                            </td>
                          </tr>
                          </tfoot>
                        )}
                      </table>
                    </ToggleLayout>
                    <ToggleLayout imgType={"feather"} feather={"truck"} title={t("title.COLLECTION_CENTER_DETAILS")}
                                  visibleToggleIndex={visibleToggleIndex} toggleIndex={6}
                                  onVisibleToggleIndexChange={(index) =>
                                    setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    >
                      {supplierReceiptData.collectionCenterDetails && supplierReceiptData.collectionCenterDetails.length > 0 &&
                        supplierReceiptData.collectionCenterDetails.map((center, index) => (
                          <div key={index} className={"row farm-info-set single-card py-1 m-1"}
                               style={{background: "#F9F9F9"}}>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.COLLECTION_CENTER_NAME")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.corporateName || "NA"}</div>
                            </div>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.QUANTITY")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.totalQuantity || "NA"}</div>
                            </div>
                            <div className={"col-md-4 col-lg-3"}>
                              <div className={"farm-info-title"}>{t("title.ADDRESS")}</div>
                              <div className={"farm-info-data m-b-8"}>{center.corporateAddress || "NA"}</div>
                            </div>
                          </div>
                        ))}
                    </ToggleLayout>
                  </Container>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealsSummary;
