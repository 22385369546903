import React, {useEffect, useState} from 'react';
import PlotIrrigationManual from "./plot-irrigation-manual";
import {useParams} from "react-router-dom";
import {IRRIGATION_MODE} from "../../../utils/Enum";
import {hasPermission,} from "../../../utils/Authentication";
import PlotIrrigationSchedule from "./plot-irrigation-schedule";
import PlotIrrigationDaily from "./plot-irrigation-daily";
import PlotIrrigationAutomation from "./plot-irrigation-automation";
import FeatherIcon from "feather-icons-react";
import FarmIrrigationModeChange from "../farm/irrigation-mode-change";
import {useDispatch, useSelector} from "react-redux";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {useTranslation} from 'react-i18next';
import {isEmpty} from "../../../shared/utils/utils";
import DevicesMap from '../farm/device-map';
import ScheduleHistoryTable from "./schedule-history-table";
import AutomationHistoryTable from "./automation-history-table";
import ManualHistoryTable from "./manual-history-table";
import ReactTooltip from "react-tooltip";
import Popup from "../../popup";
import {getUserId, isSuper} from "../../../shared/utils/SharedAuthentication";
import Container from "react-bootstrap/Container";
import ValveMap from "../farm/valve-map";
import Actuator from "../farm/actuator";
import Switch from "react-switch";
import axios from "axios";
import {toast} from "react-toastify";


const PlotIrrigationSetting = (props) => {
  // const {plotId} = useParams();
  const {farmId} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [irrigation, setIrrigation] = useState(null);
  const [irrigationId, setIrrigationId] = useState('');
  const [plotId, setPlotId] = useState('')
  const [reference, setReference] = useState('')
  const {irrigations, setIrrigations} = useState([])
  const [isAddIrrigation, setIsAddIrrigation] = useState(null);
  const [map, setMap] = useState(null);
  const [isLoadMode, setIsLoadMode] = useState(true);
  const [updateManualData, setUpdateManualData] = useState(true);
  const [updateAutomationData, setUpdateAutomationData] = useState(true);
  const [updateSchedulesData, setUpdateSchedulesData] = useState(true);
  const [buttonHide, setButtonHide] = useState(true);
  const [modeChangeHide, setModeChangeHide] = useState(false);
  const [irrigationMode, setIrrigationMode] = useState('')

  const plotMqttStatus = useSelector(state => {
    return state.plotList.plotMqtt;
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  const clusters = useSelector(state => {
    return state.sensor.clusters;
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  // useEffect(() => {
  //   if (!isLoadMode) {
  //     return
  //   }
  //   dispatch(toggleLoader(true));
  //   axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation')
  //     .then(function (response) {
  //       let irr = {};
  //       if (response.data.content) {
  //         irr=toBrowserTimeZoneAll(response.data.content);
  //       }
  //       setIrrigations(irr);
  //       if (!response.data.content || !response.data.content.mode || !response.data.content.valveCode || [IRRIGATION_MODE.DAILY, IRRIGATION_MODE.MANUAL].includes(response.data.content.mode)) {
  //         setButtonHide(true);
  //       } else {
  //         setButtonHide(false);
  //       }
  //       setIsLoadMode(false);
  //     })
  //     .catch(function (error) {
  //       if (error.response && error.response.status === 422) {
  //       }
  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     })
  // }, []);

  // useEffect(() => {
  //   setIsLoadMode(true);
  // }, [props.plotId]);

  function addIrrigation() {
    setIsAddIrrigation(true);
  }

  function toggleDebugging(event) {

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/assign-batch')
      .then(function (response) {
        toast.success("Enabled irrigation debugging");
      })
      .catch(function (error) {
        console.error("response error", error)
      })
      .finally(() => {
        dispatch(toggleLoader(false))
      })
  }

  function toBrowserTimeZoneAll(irrigation) {
    // if (irrigation.schedules) {
    //   irrigation.schedules.forEach(e=>{
    //     // console.log(e.startTime);
    //     // console.log(toBrowserTimeZone("2022-01-01 "+e.startTime+":00"))
    //     let converted = toBrowserTimeZone("2022-01-01 " + e.startTime + ":00");
    //     irrigation.startTime = converted.substring(11, 16);
    //
    //     converted=toBrowserTimeZone("2022-01-01 " + e.endTime + ":00");
    //     irrigation.endTime = converted.substring(11, 16);
    //   })
    // }
    return irrigation;
  }


  useEffect(() => {
    if (!plotMqttStatus || !plotMqttStatus.mode || isLoadMode) {
      return;
    }
    if (irrigation && plotMqttStatus.mode !== irrigation.mode) {
      dispatch(toggleConfirmationDialog({
        isVisible: true,
        type: "request",
        confirmationHeading: t("confirmation_msg.IRRIGATION_MODE_CHANGED"),
        confirmationDescription: t("confirmation_msg.SOMEONE_HAS_CHANGED_IRRIGATION_MODE_OF_THIS_PLOT_PLEASE_REFRESH_THE_PAGE_TO_GET_THE_UPDATE")
      }));
    }
  }, [plotMqttStatus]);

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    setIsLoadMode(true);
  }, [confirmationDialog]);


  function updateIrrigation(res) {
    setIrrigation(res);
  }

  function getIrrigation(irr) {
    // console.log("irrigation 127 ", irr)
    if (!irr || !irr.valveCode) {
      return null;
    }
    // console.log("get irrigation ", irr)
    switch (irr.mode) {
      case IRRIGATION_MODE.MANUAL:
        return (
          <div style={{marginLeft: 600, marginTop: 150}}><PlotIrrigationManual plotId={reference} irrigationId={irr.id}
                                                                               farmId={farmId}
                                                                               updateData={() => setUpdateManualData(!updateManualData)}
                                                                               irrigation={irr}/>
          </div>);

      case IRRIGATION_MODE.SCHEDULE:
        return (<div style={{marginLeft: 410, marginTop: 100}}><PlotIrrigationSchedule
          irrigationId={irrigationId}
          addIrrigation={addIrrigation}
          update={() => setUpdateSchedulesData(!updateSchedulesData)}
          changeAdd={() => setIsAddIrrigation(false)} hide={(hide) => {
          setButtonHide(hide);
          setModeChangeHide(hide)
        }} onReload={() => setIsLoadMode(true)}
          isAddIrrigation={isAddIrrigation} plotId={reference} irrigation={irrigation}/></div>);
      case IRRIGATION_MODE.AUTOMATION:
        return (<div style={{marginLeft: 400, marginTop: 150}}><PlotIrrigationAutomation plotId={reference}
                                                                                         irrigationId={irrigationId}

                                                                                         isAddIrrigation={isAddIrrigation}
                                                                                         irrigation={irrigation}
                                                                                         update={() => setUpdateAutomationData(!updateAutomationData)}
                                                                                         updateIrrigation={(res) => updateIrrigation(res)}
                                                                                         changeAdd={() => setIsAddIrrigation(false)}
                                                                                         hide={(hide) => {
                                                                                           setButtonHide(hide);
                                                                                           setModeChangeHide(hide)
                                                                                         }}
        /></div>);
      case IRRIGATION_MODE.DAILY:
        return (<div style={{marginLeft: 600, marginTop: 80}}><PlotIrrigationDaily irrigationId={irrigationId}
                                                                                   plotResponse={irrigation}
                                                                                   plotId={reference}/></div>);

    }
  }

  function getIrrigationTable(irr) {
    // console.log("irrigation 127 ", irr)
    if (!irr || !irr.valveCode) {
      return null;
    }
    // console.log("get irrigation ", irr)
    switch (irr.mode) {
      case IRRIGATION_MODE.MANUAL:
        return (
          <div>
            <ManualHistoryTable plotId={reference}
                                irrigationId={irr.id}
                                updated={updateManualData}
                                irrigation={irr}/>
          </div>);

      case IRRIGATION_MODE.SCHEDULE:
        return (<div><ScheduleHistoryTable
          irrigationId={irrigationId}
          addIrrigation={addIrrigation}
          updateSchedulesData={updateSchedulesData}
          changeAdd={() => setIsAddIrrigation(false)} hide={(hide) => {
          setButtonHide(hide);
          setModeChangeHide(hide)
        }} onReload={() => setIsLoadMode(true)}
          isAddIrrigation={isAddIrrigation} plotId={reference} irrigation={irrigation}/></div>);
      case IRRIGATION_MODE.AUTOMATION:
        return (<div>
          <AutomationHistoryTable plotId={reference}
                                  irrigationId={irrigationId}
                                  updateData={updateAutomationData}

                                  isAddIrrigation={isAddIrrigation}
                                  irrigation={irrigation}
                                  updateIrrigation={(res) => updateIrrigation(res)}
                                  changeAdd={() => setIsAddIrrigation(false)}
                                  hide={(hide) => {
                                    setButtonHide(hide);
                                    setModeChangeHide(hide)
                                  }}
          />
        </div>);
      case IRRIGATION_MODE.DAILY:
        return (<div style={{marginLeft: 600, marginTop: 80}}>
          {/*<PlotIrrigationDaily irrigationId={irrigationId}*/}
          {/*                                                                         plotResponse={irrigation}*/}
          {/*                                                                         plotId={reference}/>*/}
        </div>);

    }
  }

  function onModeChange(mode) {
    setIsLoadMode(true);
    setIrrigation({...irrigation, mode: mode})
    if (mode === IRRIGATION_MODE.SCHEDULE) {
      setButtonHide(false)
    }
    setIrrigationMode(mode)
  }

  return (
    <div style={{minHeight: 460}}>
      {hasPermission("SMART_FARM.PLOTS.PLOT_IRIGAION_SETTINGS", "ADD") && (irrigationMode === IRRIGATION_MODE.SCHEDULE || (irrigationMode === IRRIGATION_MODE.AUTOMATION && selectedPlot.kitId)) &&
        <div style={{marginLeft: '-100%'}} className="pull-right m-t-25 ">
          <button
            // hidden={(  buttonHide || isAddIrrigation || (clusters.length === 0 && irrigation && irrigation.mode === IRRIGATION_MODE.AUTOMATION))}
            className="btn btn-sa-secondary pis-add" style={{padding: 8}} onClick={addIrrigation}
            data-tip={irrigationMode === IRRIGATION_MODE.SCHEDULE}
            data-for="addIrrigationTooltip">
            <FeatherIcon
              icon={"plus"}/> <span className={"hide-375"}>{t("button.ADD")}</span>
          </button>
          {irrigationMode === IRRIGATION_MODE.SCHEDULE && (
            <ReactTooltip id="addIrrigationTooltip" place="top" className="toolTipTheme" effect="solid">
              <p>{t("instructions.ADD_SCHEDULES_BEFORE_15_MINUTES")}</p>
            </ReactTooltip>
          )}
        </div>
      }

      <div style={{width: "110%"}}>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <DevicesMap
            isPlotSettings={props.isPlotSettings}
            irrigation={irrigation}

            plotId={props.plotId}
            setIrrigationId={(value) => {


              setIrrigationId(value)
            }}
            setReference={(value) => {
              setReference(value)
            }}
            setIrrigation={(value) => {
              if (value.mode == "SCHEDULE" || value.mode == "AUTOMATION") {
                setButtonHide(false)
              }

              setIrrigationMode(value.mode)

              setIrrigation(value)
            }}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDobm_3OLsu0cFTLt-zUAgt-_7Oi-TkGT4&v=3.exp&libraries=geometry,drawing,places,visualization"
            loadingElement={<div style={{height: `100%`}}/>}
            containerElement={<div style={{height: `400px`, zIndex: 1, width: 100}}/>}
            mapElement={<div style={{height: `100%`, width: 500}}/>}
          />
          {!(!props.isModeOnly && isEmpty(irrigation)) &&
            <FarmIrrigationModeChange plotId={props.plotId} valveCode={irrigation && irrigation.valveCode}
                                      hideButton={!props.isModeOnly && (modeChangeHide || isAddIrrigation)}
                                      changeIrrigation={isLoadMode} onModeChange={onModeChange}
                                      irrigationMode={irrigationMode && irrigationMode}
                                      setIrrigationMode={(value) => {
                                        setIrrigationMode(value);

                                      }}
                                      irrigationName={irrigation.irrigationName}
                                      farmId={farmId}/>}
          {irrigation && !irrigation.valveCode && (
            <div style={{marginLeft: 400}} className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div
                className={"empty-results-text"}>{t("title.VALVE_HAS_NOT_BEEN_CONFIGURED_TO_THIS_PLOT_PLEASE_ADD")}</div>
            </div>)
          }


          {irrigation && getIrrigation(irrigation)}
          {/*{irrigation && getIrrigationTable(irrigation)}*/}
        </div>
      </div>

      {/*<CommonPopup></CommonPopup>*/}

      {!props.isModeOnly && isEmpty(irrigation) && (
        <div style={{marginLeft: "40%", marginTop: -300, width: "60%"}} className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.PLEASE_SLECT_AN_IRRIGATION")} </div>

        </div>)
      }

      {isSuper() &&
        <Container className={'pb-3'}>
          <Popup title={t("title.VALVE_MAP")}>
            <ValveMap farmId={farmId}/>
          </Popup>
          <Popup title={t("title.ACTUATOR")}>
            <Actuator/>
          </Popup>

          <div className={"d-flex justify-content-between flex-wrap pis-pull-right"}>
            <div className={"d-flex"}>
              <div className={"irrigation-mode-heading"}>Enable irrigation debugging</div>
              <div className={"ml-3"}>
                <Switch
                  checked={false}
                  onChange={toggleDebugging}
                  onColor="#22C674"
                  offColor="#D3D3D3"
                  onHandleColor="#fff"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={25}
                  width={46}
                  className="react-switch ml-auto"
                  id="material-switch"
                />
              </div>
            </div>
          </div>
        </Container>


      }

      {irrigation && getIrrigationTable(irrigation)}

      {/*<ScheduleHistoryTable/>*/}


    </div>
  )
};

export default PlotIrrigationSetting
