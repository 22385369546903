import React from "react";
import image from "../../images/company.jpg";
import {useHistory} from "react-router";

const AccountItem = (props) => {
  const history = useHistory()

  function goTo() {
    history.push("/account/" + props.account.id + "/farmer-detail")
  }

  return (
    <div className="single-card ma-res-bot sa-cursor" id={"corporate-card"}>
      <div className={"single-card-header"} id={"corporate-card-header"}>
        <div onClick={(e) => {
          if (e.target.parentNode.id === "corporate-card" || e.target.parentNode.id === "corporate-card-header" || e.target.parentNode.id === "farmImg") {
            goTo()
          }
        }}>
          <div className="card-img-view" id={"farmImg"}>
            <img src={props.account.companyLogo ? props.account.companyLogo : image} className="card-img"
                 alt="account"/>
          </div>
          <div className="card-header-text limit-charater-farmname">
            <label className="heading">{props.account.name}</label>
            <div title={"contactName"} className="sub-text">
              {props.account.contactName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountItem;
